import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import icon_weather_dust from '../assets/icon_weather_dust.svg'
import icon_weather_fog from '../assets/icon_weather_fog.svg'
import icon_weather_funnel from '../assets/icon_weather_funnel.svg'
import icon_weather_hail from '../assets/icon_weather_hail.svg'
import icon_weather_rain from '../assets/icon_weather_rain.svg'
import icon_weather_smoke from '../assets/icon_weather_smoke.svg'
import icon_weather_snow from '../assets/icon_weather_snow.svg'
import icon_weather_squall from '../assets/icon_weather_squall.svg'
import icon_weather_thunderstorm from '../assets/icon_weather_thunderstorm.svg'
import icon_weather_volcano from '../assets/icon_weather_volcano.svg'

const CurrentWeatherDiv = styled.div`
    .weather-container {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        box-shadow: rgba(194, 208, 209, .5) 0 0 10px;
        color: #475467;
        display: inline-flex;
        flex-direction: column;
        font-size: 12px;
        justify-content: space-between;
        padding: 12px 20px 10px;
        width: 160px;
    }

    .title,
    .current-temperature {
        color: #02222B;
        font-weight: 600;
    }

    .title {
        font-size: 14px;  
    }

    hr {
        border: 1px solid #D0D5DD;
        margin: 10px 0;
        width: 100%;
    }
    
    .weather-details {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .current-temperature {
        font-size: 18px;
    }

    .current-condition {
        display: flex;
        font-weight: 500;
        
        p {
            margin: 0;
        }
    }
`

const CurrentWeather = ({ currentCampusData }) => {
    const [weatherCondition, setWeatherCondition] = useState("")
    const [weatherModifier, setWeatherModifier] =  useState("")
    const [currentTemperature, setCurrentTemperature] =  useState(null)
    
    let conditionIcon
    
    useEffect(() => {
        if (currentCampusData) {
            setCurrentTemperature(currentCampusData?.weather?.temperature)
            setWeatherCondition(currentCampusData?.weather?.weatherConditions?.weather)
            setWeatherModifier(currentCampusData?.weather?.weatherConditions?.modifier)
        }
    }, [currentCampusData])

    if (weatherCondition === 'drizzle' ||
        weatherCondition === 'rain' 
    ) {
        conditionIcon = icon_weather_rain
    } else if (weatherCondition === 'dust' ||
        weatherCondition === 'dust_storm' ||
        weatherCondition === 'dust_whirls' ||
        weatherCondition === 'sand' ||
        weatherCondition === 'sand_storm') {
            conditionIcon = icon_weather_dust
    } else if (weatherCondition === 'fog' ||
        weatherCondition === 'fog_mist' ||
        weatherCondition === 'haze') {
            conditionIcon = icon_weather_fog   
    } else if (weatherCondition === 'funnel_cloud') {
            conditionIcon = icon_weather_funnel
    } else if (weatherCondition === 'hail') {
        conditionIcon = icon_weather_hail
    } else if (weatherCondition === 'ice_crystals' ||
        weatherCondition === 'ice_pellets' ||
        weatherCondition === 'snow' ||
        weatherCondition === 'snow_grains' ||
        weatherCondition === 'snow_pellets') {
            conditionIcon = icon_weather_snow
    } else if (weatherCondition === 'smoke') {
        conditionIcon = icon_weather_smoke
    } else if (weatherCondition === 'squalls') {
        conditionIcon = icon_weather_squall
    } else if (weatherCondition === 'thunderstorms') {
        conditionIcon = icon_weather_thunderstorm
    } else if (weatherCondition === 'volcanic_ash') {
        conditionIcon = icon_weather_volcano
    }
    
    return (
        <CurrentWeatherDiv>
            {currentCampusData?.weather && (
                <div className="weather-container">
                    <div className="title">Current Weather</div>

                    <hr/>

                    <div className="weather-details">
                        <div className="current-temperature">
                            {currentTemperature !== null && currentTemperature}° F
                        </div>
                        {weatherCondition && weatherModifier && (
                            <div className="current-condition">
                                {conditionIcon && (
                                    <img src={conditionIcon} alt='rain icon' width="15" height="15" style={{marginRight: 5}}/>
                                )}
                                {weatherModifier} {weatherCondition}
                            </div>
                        )}
                    </div>
                </div>
            )}
    </CurrentWeatherDiv>
    )
}

export default CurrentWeather