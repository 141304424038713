import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import FormTemperatureBox from './FormTemperatureBox'
import icon_info from '../../assets/icon_info.svg'

const ModeSettings = styled.div`
    display: flex;
    flex-flow: column;
    height: 170px;
    justify-content: space-between;
    position: relative;
    width: 100%;

    .frm-schedule-event.desktop .lowerUpperMode-container .bounds-temperatures {
        flex-direction: row;
        justify-content: space-between;
        width: 200px;
    }

    .bounds-temperatures {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .mode-container {
        align-items: flex-start;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;

        label {
            align-items: center;
            display: flex;
            font-weight: 500;
            padding-bottom: 12px;

            img {
                cursor: pointer;
                padding-left: 4px;
            }
        }
        
        select {
            border: 2px solid #EAECF0;
            box-shadow: none;
            line-height: 24px;
            padding: 10px 16px;
            width: 100%;
        }
    }
`

const ModeBlock = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px #e0e0e0;
    font-size: 12px;
    height: 200px;
    padding: 12px 0 12px 12px;
    position: absolute;
    top: 122px;
    &::after {
        border-bottom: 10px solid white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        content: '';
        left: 38px;
        position: absolute;
        top: -20px;
    }

    .main-content {
        height: 200px;
        overflow: hidden;
        overflow-y: auto;
        padding-right: 12px;
        width: 90%;
    }

    .mode-content {
        span {
            font-weight: 600;
        }
    }

    .note-content {
        font-style: oblique;
        
        ul {
            padding-left: 22px;

            li {
                margin: 10px 0 0;
            }
        }
    }
`

const LowerUpperMode = ({
    handleChange,
    index,
    selectedLower,
    selectedUpper,
    selectedBlockCooling,
    selectedBlockHeating
}) => {
    let convertedMode

    if (selectedBlockCooling === false && selectedBlockHeating === false) {
        convertedMode = "auto"
    } else if (selectedBlockCooling === true && selectedBlockHeating === true) {
        convertedMode = "fan"
    } else if (selectedBlockCooling === true && selectedBlockHeating === false) {
        convertedMode = "heat"
    } else if (selectedBlockCooling === false && selectedBlockHeating === true) {
        convertedMode = "cool"
    }

    const [showInfo, setShowInfo] = useState(false)
    const infoRef = useRef(null)

    useEffect(() => {
        window.addEventListener('click', hideInfo)

        return () => {
            window.removeEventListener('click', hideInfo)
        }
    }, [showInfo])

    const hideInfo = (e) => {
        if (showInfo) {
            if (e.target !== infoRef.current) {
                setShowInfo(false)
            }
        }
    }

    return (
        <ModeSettings className="lowerUpperMode-container">
            <div className="bounds-temperatures">
                <FormTemperatureBox
                    label="Lower Bound"
                    name="lower_bound"
                    defaultValue={selectedLower ? selectedLower : 68}
                    updateField={(e) => handleChange(index, 'lowerBound', e)} 
                />

                <FormTemperatureBox
                    label="Upper Bound"
                    name="upper_bound"
                    defaultValue={selectedUpper ? selectedUpper : 75}
                    updateField={(e) => handleChange(index, 'upperBound', e)}
                />
            </div>
    
            <div className="mode-container">
                <label>
                    Mode <img src={icon_info} width="12" height="12" onClick={() => setShowInfo(!showInfo)} ref={infoRef}></img>
                </label>
                <select 
                    onChange={(e) => handleChange(index, e.target.value, e.target.value)}
                    defaultValue={convertedMode ? convertedMode : "auto"}
                >
                    <option value="auto">Auto</option>
                    <option value="heat">Heat Only</option>
                    <option value="cool">Cool Only</option>
                    <option value="fan">Fan Only</option>
                </select>
            </div>

            {showInfo && (
                <ModeBlock>
                    <div className="main-content">
                        <div className="mode-content">
                            <p><span>Auto:</span> The Elexity system can heat or cool as needed in order to most effectively manage both comfort and cost.</p>
                            
                            <p><span>Cool Only:</span> The Elexity system will not call for heating during this period.<span>*</span></p>
                            
                            <p><span>Heat Only:</span> The Elexity system will not call for cooling during this period.<span>*</span></p>
                            
                            <p><span>Fan Only:</span> The Elexity system will not call for cooling or heating during this period.<span>*</span></p>
                        </div>

                        <div className="note-content">
                            *Note that, under some circumstances, the Cool / Heat / Fan Only flag will be overruled:
                            <ul>
                                <li>
                                    Buildings will not be allowed to cool below 40F / 5C, in order to prevent freezing damage.
                                </li>
                                <li>
                                    If the following time period allows for heating or cooling, the Elexity system may turn on heating or cooling early in order to “ramp” the temperature to the desired setpoint.
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModeBlock>
            )}
        </ModeSettings>
    )
}

export default LowerUpperMode