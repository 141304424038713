import React from 'react'
import styled from '@emotion/styled'
import FormTemperatureBox from './FormTemperatureBox'

const Default = styled.div`
    .default-container {
        align-items: center;
        background: #F2F4F7;
        border-radius: 8px;
        color: #324E55;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 14px;

        .title {
            font-size: 14px;
            font-weight: 500;
        }

        .default-items {
            align-items: center;
            display: flex;
            font-size: 12px;
            justify-content: space-around;
            width: 50%;
        } 
    }

    .default-container.mobile {
        flex-flow: column;

        .default-items {
            width: 100%;
        }
    }
    
`

const ModeSettings = styled.div`
    align-items: center;
    display: flex;
    flex-flow: row;
    height: auto;
    justify-content: space-between;
    width: 100%;

    .mode-container,
    .bounds-temperatures {
        width: 48%;
    }

    .bounds-temperatures {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .temperature-box-header {
            color: #324E55;
            padding: 0;
        }

        .temperature-box {
            height: 42px;
            padding: 0 8px;
            width: 48px;

            .selected-temperature {
                font-size: 20px;
                margin-top: 6px;
            }

            .increment-temp,
            .decrement-temp {
                padding: 4px 0 0;

                input {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    .mode-container {
        align-items: flex-start;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;

        label {
            font-weight: 500;
            padding-bottom: 5px;
        }

        select {
            border: 2px solid #EAECF0;
            box-shadow: none;
            line-height: 13px;
            padding: 10px 16px;
            width: 100%;
        }
    }
    
`

const TempModeDefaults = ({
    defaultBlockCooling,
    defaultBlockHeating,
    defaultLowerBound,
    defaultUpperBound,
    selectedDefaultBlockCooling,
    selectedDefaultBlockHeating,
    selectedDefaultLowerBound,
    selectedDefaultUpperBound,
    handleChange,
    showMobile,
}) => {
    let convertedMode

    if (selectedDefaultBlockCooling !== null) {
        if (selectedDefaultBlockCooling === false && selectedDefaultBlockHeating === false) {
            convertedMode = "auto"
        } else if (selectedDefaultBlockCooling === true && selectedDefaultBlockHeating === true) {
            convertedMode = "fan"
        } else if (selectedDefaultBlockCooling === true && selectedDefaultBlockHeating === false) {
            convertedMode = "heat"
        } else if (selectedDefaultBlockCooling === false && selectedDefaultBlockHeating === true) {
            convertedMode = "cool"
        }
    } else {
        if (defaultBlockCooling === false && defaultBlockHeating === false) {
            convertedMode = "auto"
        } else if (defaultBlockCooling === true && defaultBlockHeating === true) {
            convertedMode = "fan"
        } else if (defaultBlockCooling === true && defaultBlockHeating === false) {
            convertedMode = "heat"
        } else if (defaultBlockCooling === false && defaultBlockHeating === true) {
            convertedMode = "cool"
        }
    }


    
    return (
        <Default>
            <div className={showMobile ? 'default-container mobile' : 'default-container'}>
                <div className="title">Unoccupied</div>

                <div className="default-items">
                    <ModeSettings className="lowerUpperMode-container">
                        <div className="bounds-temperatures">
                            <FormTemperatureBox
                                label="Lower Bound"
                                name="lower_bound"
                                defaultValue={selectedDefaultLowerBound ? 
                                    selectedDefaultLowerBound 
                                    :
                                    defaultLowerBound}
                                updateField={(e) => handleChange(null, 'defaultLowerBound', e)} 
                            />

                            <FormTemperatureBox
                                label="Upper Bound"
                                name="upper_bound"
                                defaultValue={selectedDefaultUpperBound ? 
                                    selectedDefaultUpperBound 
                                    :
                                    defaultUpperBound}
                                updateField={(e) => handleChange(null, 'defaultUpperBound', e)}
                            />
                        </div>

                        <div className="mode-container">
                            <label>
                                Mode
                            </label>
                            <select 
                                onChange={(e) => handleChange(null, 'defaultMode', e.target.value)}
                                defaultValue={convertedMode ? convertedMode : "auto"}
                            >
                                <option value="auto">Auto</option>
                                <option value="heat">Heat Only</option>
                                <option value="cool">Cool Only</option>
                                <option value="fan">Fan Only</option>
                            </select>
                        </div>
                    </ModeSettings>
                </div>
            </div>
        </Default>
    )
}

export default TempModeDefaults