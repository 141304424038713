const SAVE_CAMPUSES = 'SAVE_CAMPUSES'
const SET_CURRENT_CAMPUS = 'SET_CURRENT_CAMPUS'
const SET_CURRENT_BUILDING = 'SET_CURRENT_BUILDING'
const SET_CURRENT_ZONE = 'SET_CURRENT_ZONE'
const SET_CURRENT_GX = 'SET_CURRENT_GX'
const SET_CURRENT_GXDATA = 'SET_CURRENT_GXDATA'
const SET_USER_DATA = 'SET_USER_DATA'
const UPDATE_CURRENT_CAMPUSDATA = 'UPDATE_CURRENT_CAMPUSDATA'

const saveCampuses = (data) => {
  return {
    type: SAVE_CAMPUSES,
    data
  }
}

const setCurrentCampus = (campusId) => {
  return {
    type: SET_CURRENT_CAMPUS,
    campusId
  }
}

const setCurrentBuilding = (buildingId) => {
  return {
    type: SET_CURRENT_BUILDING,
    buildingId
  }
}

const setCurrentZone = (zoneId) => {
  return {
    type: SET_CURRENT_ZONE,
    zoneId
  }
}

const setCurrentGX = (gxId) => {
  return {
    type: SET_CURRENT_GX,
    gxId
  }
}

const setCurrentGXData = (gxData) => {
  return {
    type: SET_CURRENT_GXDATA,
    gxData
  }
}

const updateCurrentCampusData = (campusData) => {
  return {
    type: UPDATE_CURRENT_CAMPUSDATA,
    campusData
  }
}

const setUserData = (userData) => {
  return {
    type: SET_USER_DATA,
    userData
  }
}

export {
  saveCampuses,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  setCurrentGXData,
  updateCurrentCampusData,
  setUserData,
}