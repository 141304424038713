import React, { useEffect } from 'react'
import {connect} from 'react-redux'
import styled from '@emotion/styled'

import WithRouter from '../utilities/WithRouter'
import { setCurrentCampus } from '../actions'

import icon_notifications from '../assets/icon_notifications.svg'

const NotificationsDiv = styled.div`
    background: rgba(237, 241, 242, 0.4);
    color: #02222B;
    height: 100vh;
    overflow-x: scroll;

    .desktop {
        margin: 36px 24px 30px 114px;
    }

    .mobile {
        margin: 36px 24px;

        .notifications-section {
            justify-content: center;
        }
        td:nth-of-type(2) {
            width: 125px;
        }
    }

    .header {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 24px;

        h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            margin: 0;
        }

        img {
            padding-right: 8px;
        }
    }

    h4 {
        font-size: 16px;
        margin: 0;
    }

    hr {
        background: #C2D0D1;
        border: none;
        height: 2px;
        margin: 12px 0 24px;
    }

    .notifications-section {
        display: flex;
        flex-flow: column;

        .new, 
        .acknowledged {
            margin-bottom: 45px;
            width: 100%;
        }

        table {
            border-spacing: 0 8px;
            text-align: left;
            width: 100%;

            th {
                border-bottom: 2px solid #C2D0D1;
                padding-bottom: 12px;
            }

            th:nth-of-type(1),
            td:nth-of-type(1) {
                padding-left: 24px;
            }
        }


        td:nth-last-of-type() {
            width: 125px;
        }

        .acknowledge-button {
            background: #02222B;
            border-radius: 50px;
            color: white;
            font-weight: 600;
            margin: 0 auto;
            max-width: 125px;
            padding: 6px 0;
            text-align: center;
        }
    }
`

const Notifications = ({ showMobile }) => {
    return (
        <NotificationsDiv>
        <div className={showMobile ? 'mobile' : 'desktop'}>
            <div className="header">
                <img src={icon_notifications} alt="bell icon" width="18" height="18" />
                <h1>Notifications</h1>
            </div>
            
            <section className="notifications-section">
                <div className="new">
                    <h4>New</h4>
                    <hr />
                    <table>
                        <thead>
                            <tr>
                                <th>Location</th>
                                {!showMobile && (<>
                                    <th>Source</th>
                                    <th>Type</th>
                                    <th>Time</th>
                                </>)}
                                <th></th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr>
                                <td>Building 1 - Zone 1</td>
                                {!showMobile && (<>
                                    <td>HVAC</td>
                                    <td>Error message goes here.</td>
                                    <td>08/30/24 10:45am</td>
                                </>)}
                                <td>
                                    <div 
                                        className="acknowledge-button"
                                        onClick={() => console.log('this will acknowledge notification!')}
                                    >
                                            Acknowledge
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="acknowledged">
                    <h4>Acknowledged</h4>
                    <hr />
                    <table>
                        <thead>
                            <tr>
                                <th>Location</th>
                                {!showMobile && (<>
                                    <th>Source</th>
                                    <th>Type</th>
                                    <th>Time</th>
                                    <th>Acknowledged By</th>
                                    <th>Acknowledged On</th>
                                </>)}
                                <th></th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr>
                                <td>Building 1 - Zone 1</td>
                                {!showMobile && (<>
                                    <td>HVAC</td>
                                    <td>Error message goes here.</td>
                                    <td>08/30/24 10:45am</td>
                                    <td>User One</td>
                                    <td>08/30/24 11:45am</td>
                                </>)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
        </NotificationsDiv>
    )
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Notifications))