import React, { useState } from 'react'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

import authentication from '../../utilities/Authentication'
import configuration from '../../config'
import Modal from '../Modal'
import FormTemperatureBox from './FormTemperatureBox'
import icon_event from '../../assets/icon_event.svg'
import LowerUpperMode from './LowerUpperMode'
import StartEndTime from './StartEndTime'

const HomepageModalScheduleEvent = ({
  clearModal,
  zoneData,
  showMobile,
  setProcessing,
  updateThermalZoneData,
  setErrorMessage
}) => {
  const newDate = new Date()
  let year = newDate.toLocaleString("default", { year: "numeric" });
  let month = newDate.toLocaleString("default", { month: "2-digit" });
  let day = newDate.toLocaleString("default", { day: "2-digit" });
  let formattedDate = year + "-" + month + "-" + day;

  const [startDate, setStartDate] = useState(newDate)
  const [postData, setPostData] = useState({
    eventName: `New Event: ${formattedDate}`,
    date: formattedDate,
    startTime: '06:00',
    endTime: '14:30',
    lowerBound: 68,
    upperBound: 75,
    blockCooling: false,
    blockHeating: false,
  })
  
  // const currentTimezone = moment().tz(moment.tz.guess()).format('z')
  // let timeOptions = []

  // for (let i=0; i < 24; i++) {
  //   for (let j=0; j < 4; j++) {
  //     timeOptions.push(`${i < 10 ? `0${i}` : i}:${j === 0 ? `00` : 15*j}`)
  //   }
  // }

  const handleChange = (index = null, name, value) => {
    //lowerbound upperbound mode
    if (name === 'lowerBound') {
      setPostData(
        prevState => ({
          ...prevState,
          lowerBound: value
        })
      )
    } else if (name === 'upperBound') {
      setPostData(
        prevState => ({
          ...prevState,
          upperBound: value
        })
      )
    } else if (name === 'heat') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: true,
        blockHeating: false,
        })
      )
    } else if (name === 'cool') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: false,
        blockHeating: true,
        })
      )
    } else if (name === 'fan') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: true,
        blockHeating: true,
        })
      )
    } else if (name === 'auto') {
      setPostData(
        prevState => ({
        ...prevState,
        blockCooling: false,
        blockHeating: false,
        })
      )
    }

    //start end time
    if (name === 'startTime') {
      setPostData(
        prevState => ({
        ...prevState,
        startTime: value
        })
      )
    } else if (name === 'endTime') {
      setPostData(
        prevState => ({
        ...prevState,
        endTime: value
        })
      )
    }
  }

  const handleDateSelect = (selectedDate) => {
    let newDay = selectedDate.getDate() < 10 ? `0${selectedDate.getDate()}` : selectedDate.getDate()
    let indexMonth = selectedDate.getMonth() + 1
    let newMonth = indexMonth < 10 ? `0${indexMonth}` : indexMonth
    
    setStartDate(selectedDate)
    setPostData(
      prevState => ({
        ...prevState,
        date: selectedDate.getFullYear()
        + '-' +
        newMonth
        + '-' +
        newDay
      })
    )
  }

  const saveScheduleEventSettings = () => {
    setProcessing(true)
    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    const encodeGetParams = p => 
      Object.entries(p).map(item => item.map(encodeURIComponent).join("=")).join("&")
 
    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + zoneData.thermalZoneEntityKey
    + '/thermalZone/event', 
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: encodeGetParams(postData)
    })
  
    .then(async response => {
      if (!response.ok) {
        const res = await response.json()

        setErrorMessage(res.message)

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)
        throw new Error(res.message)
      } else {
        updateThermalZoneData(zoneData.thermalZoneEntityKey)
    
        return response.json()
      }
    })

    .then(() => {
      setProcessing(false)
    })

    .catch(error => {
      setProcessing(false)
      console.error(error)
    })
  }

  return (
    <Modal
      modalTitle={"Schedule Event"}
      modalDescription={"Create an event for the current zone."}
      modalImage={icon_event}
      clearModal={clearModal}
      updateSettings={saveScheduleEventSettings}
      showMobile={showMobile}
      renderModalContent={(
        <div className={showMobile ? "modal-homepage-zone-settings" : "modal-homepage-zone-settings desktop"}>
          <div className="home-schedule-event-fields-container">
            <div className="frm-field-container">
              <label>
                Event Name
                <div>
                  <input
                    type="text"
                    name="event_name"
                    maxLength="30"
                    placeholder={`New Event: ${formattedDate}`}
                    onChange={(e) => setPostData(
                      prevState => ({
                        ...prevState,
                        eventName: e.target.value.replace(/\s/g, '+')
                      })
                    )}
                    />
                </div>
              </label>
            </div>
            <div className="frm-field-container">
              <label>
                Date
                <div className="date-input-container">
                  <DatePicker 
                    selected={startDate}
                    onChange={(date) => handleDateSelect(date)}
                  />
                </div>
              </label>
            </div>
            <StartEndTime handleChange={handleChange}/>

            <LowerUpperMode 
              handleChange={handleChange}
            />
          </div>
        </div>
      )}
    /> 
  )
  
}

export default HomepageModalScheduleEvent