import PropTypes from "prop-types"
import styled from "@emotion/styled"

const StatDetail = styled.div`
.stats-container {
  display: flex;
  flex-direction: column;
  height: 217px;
  justify-content: flex-start;
}

.stats {
  display: flex;
  background: white;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  justify-content: space-between;
  line-height: 1.25;
  margin-bottom: 8px;
  margin-left: 24px;
  padding: 12px 15px;
  width: 210px;
  &.zone-range {
      flex-direction: column;
  }
}

.stats > div {
  color: #324E55;
  font-weight: 500;
}
`

function StatsDetail(props) {
  const { variant = '', children, ...rest } = props
  
  return (
    <StatDetail className={`stat-card ${variant}`} {...rest}>
      {children}
    </StatDetail>
  )
}

StatsDetail.propTypes = {
  handleClick: PropTypes.func,
  variant: PropTypes.string,
}

export default StatsDetail