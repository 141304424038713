import React, { useState } from 'react'
import Modal from './Modal'
import authentication from '../utilities/Authentication'
import configuration from '../config'
import AuthenticatedComponent from '../utilities/AuthenticatedComponent'

import icon_override from '../assets/icon_override.svg'
import icon_plus from '../assets/icon_plus.svg'
import icon_minus from '../assets/icon_minus.svg'

const HomepageModalRemoteOverride = ({
  clearModal,
  zoneData,
  showMobile,
  updateThermalZoneData,
  openModal,
  setProcessing,
  setRemoteSave
}) => {
  const [setpoint, updateSetpoint] = useState(72)
  const [postData, setPostData] = useState({
    mode: 'heat',
    setpoint,
    durationInMinutes: 60
  })

  const authenticatedComponent = new AuthenticatedComponent

  const decrementSetpoint = () => {
    updateSetpoint(setpoint - 1)
    setPostData(
      prevState => ({
      ...prevState,
      setpoint: setpoint - 1,
      })
    )
  }

  const incrementSetpoint = () => {
    updateSetpoint(setpoint + 1)
    setPostData(
      prevState => ({
      ...prevState,
      setpoint: setpoint + 1,
      })
    )
  }

  let modeOptions = [
    {
      label: 'Heat',
      value: 'heat'
    },
    {
      label: 'Cool',
      value: 'cool'
    },
    {
      label: 'Off',
      value: 'off'
    }
  ]

  let durationOptions = []
  const hours = new Array(24).fill(0).map((_, i) => `${i+1}`)

  for (let i = 0; i < hours.length; i++) {
    const hourOption = {
      label: '',
      value: null,
    }

    hourOption.label = hours[i] + `${hours[i] > 1 ? ' hours' : ' hour'}`

    hourOption.value = hours[i] * 60

    durationOptions.push(hourOption)
  }

  const saveRemoteOverrideSettings = () => {
    setRemoteSave(true)
    setProcessing(true)

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    fetch (configuration['backend_host'] + '/ahiapi/'
    + zoneData.thermalZoneEntityKey
    + '/thermalZone/override', 
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `mode=${postData.mode}&` 
      + `setpoint=${postData.setpoint}&`
      + `durationInMinutes=${postData.durationInMinutes}`
    })

    .then(async response => {
      setProcessing(true)
      const res = await response.json()
      if (!response.ok) {
        throw new Error(res.message)
      } else {
        if (res) {
          updateThermalZoneData(zoneData.thermalZoneEntityKey)
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        setRemoteSave(false)
      }, 5000)
    })

    .catch(error => {
      console.error(error)
    })
  }

  return (
    <Modal
      modalTitle={"Remote Override"}
      modalDescription={"Select the settings for this override."}
      modalImage={icon_override}
      clearModal={clearModal}
      showMobile={showMobile}
      updateSettings={saveRemoteOverrideSettings}
      renderModalContent={
        (
          <div className="modal-homepage-zone-settings modal-temporary-adjustment">
            <div className="temperature-adjustment">
              <div className="setpoint">
              {setpoint}
                <div
                  className="decrement-setpoint"
                  onClick={decrementSetpoint}
                ><img src={icon_minus} alt='decrease icon' width="20" height="20" /></div>
                <div
                  className="increment-setpoint"
                  onClick={incrementSetpoint}
                ><img src={icon_plus} alt='increase icon' width="20" height="20" /></div>
              </div>
            </div>

            <div className="settings-row">
              <label>
                Mode
              </label>
              <div className="setting-input">
                <select
                  name="mode"
                  onChange={(e) => setPostData(prevState => ({
                    ...prevState,
                    mode: e.target.value,
                    })
                  )}
                >
                  {modeOptions.map((option) => (
                    <option
                      value={option.value}
                      key={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="settings-row">
              <label>
                Duration
              </label>
              <div className="setting-input">
                <select
                  name="duration"
                  onChange={(e) => setPostData(prevState => ({
                    ...prevState,
                    durationInMinutes: parseInt(e.target.value),
                    })
                  )}
                >
                  {durationOptions.map((option) => (
                    <option
                      value={option.value}
                      key={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )
      }
    />
  )
  
}

export default HomepageModalRemoteOverride