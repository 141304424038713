import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

const ZonePicker = styled.div`
    overflow: hidden;
    overflow-y: auto;

    .zone-container {
        align-items: flex-start;
        border: 1px solid #EAECF0;
        border-radius: 8px;
        color: #324E55;
        display: flex;
        flex-flow: column;
        height: 245px;
        max-width: 628px;
        position: relative;
        width: 100%;

        .title {
            background: #F2F4F7;
            font-size: 12px;
            font-weight: 500;
            padding: 10px 14px;
            position: sticky;
            top: 0;
            width: 100%;
            z-index: 10;

            span {
                font-style: oblique;
            }
        }

        .zone-items {
            width: 100%;
        }
    }

    .group-items {
        display: flex;
        flex-flow: column;
    }

    .group-container,
    .group-container {
        align-items: center;
        background: #F9FAFB;
        border-bottom: 1px solid #EAECF0;
        display: flex;
        justify-content: space-between;
    }

    .expand {
        padding-right: 12px;

        img {
            height: 16px;
            transition: ease-in-out all 250ms;
            width: 16px;
        }
    }

    .weekdays-group-items.hidden,
    .weekends-group-items.hidden {
        .expand img {
            transform: rotate(180deg);
        }

        .day-item {
            display: none;
        }
    }

    .list-item {
        align-items: center;
        border-bottom: 1px solid #EAECF0;
        display: flex;
        font-weight: 600;
        padding: 10px 12px;
        width: 100%;
    }

    .zone-item {
        padding-left: 38px;
    }

    .list-item:last-child,
    .group-items:last-child {
        border-bottom: none;
    }

    .date-range label {
        border: none;
        line-height: 2;
        padding: 14px 0 0;
    }

    //Checklist styles
    .list-item input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
        
    .list-item .zone-checkmark {
        align-items: center;
        background-color: #EAECF0;
        border: solid 1px #EAECF0;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        height: 16px;
        justify-content: center;
        margin-right: 10px;
        width: 16px;
    }
        
    .list-item:hover input ~ .zone-checkmark {
        filter: brightness(0.85)
    }
        
    input:checked ~ .zone-checkmark {
        background-color: #D1FADF;
        border: solid 1px #12B76A;
        border-radius: 4px;
    }
        
    .zone-checkmark:after {
        content: "";
        position: unset;
        display: none;
    }
        
    input:checked ~ .zone-checkmark:after {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        border: solid #12B76A;
        border-width: 0 3px 3px 0;
        display: block;
        height: 8px;
        margin-bottom: 4px;
        transform: rotate(45deg);
        width: 4px;
    }

`

const ZoneSelector = ({
    handleChange,
    selectedZones,
    zoneList
}) => {
    const [selectZoneList, setSelectZoneList] = useState([])

    const handleZoneSelect = (zone) => {
        if (selectZoneList.includes(zone)) {
          const zoneIndex = selectZoneList.indexOf(zone)
          const newZone = [...selectZoneList]
          newZone.splice(zoneIndex, 1)
          setSelectZoneList(newZone)
        } else {
            //if zone not already in array, add it
            if (!selectZoneList.includes(zone)) {
            setSelectZoneList([...selectZoneList, zone])
            }
        }
    }

    useEffect(() => {
        if (selectZoneList.length === 0 && selectedZones) {
            let newSelectedZones = []

            selectedZones.map((zone) => {
                newSelectedZones.push(zone)
                setSelectZoneList(newSelectedZones)
            })
        }
    }, [selectedZones])

    useEffect(() => {
        handleChange(null, 'selectedZones', selectZoneList)
    }, [selectZoneList])

    const ZoneFilters = ({ zoneList }) => {
        return (<>
            <div className="zone-container">
                <div className="title">Select Zones - <span>Choose one or more</span></div>

                <div className="zone-items">
                    {zoneList && zoneList.map((building) => (
                        <div 
                        className={`building-${building.buildingIndex}-group-items`} 
                        key={building.buildingIndex}
                    >
                        <div className="group-container list-item">
                            <div className="group-label">
                                {building.buildingShortDescription}
                            </div>
                        </div>
                    
                        {building.thermalZones.map((zone) => (
                            <label className="zone-item list-item" key={zone.thermalZoneEntityKey}>
                                <input 
                                    type="checkbox"
                                    checked={selectZoneList.includes(zone.thermalZoneEntityKey)}
                                    onChange={() => handleZoneSelect(zone.thermalZoneEntityKey)}
                                    value={zone.thermalZoneEntityKey}
                                />
                                <span className="zone-checkmark"></span>

                                {zone.thermalZoneShortDescription}
                            </label>
                        ))}
                    </div>
                    ))}
                </div>
            </div>
        </>)
    }

    return (
        <ZonePicker className="zone-settings">
            <ZoneFilters zoneList={zoneList} />
        </ZonePicker>
    )
}

export default ZoneSelector