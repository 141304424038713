import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import WithRouter from '../../utilities/WithRouter'
import HomepageZoneCard from '../../components/HomepageZoneCard'
import { updateCurrentCampusData } from '../../actions'
import icon_filter from '../../assets/icon_filter.svg'
import { Chevron } from '../../assets/Chevron'
import CurrentWeather from '../../components/CurrentWeather'

const HomepageZoneCards = ({
  currentCampusData,
  currentBuildingZoneData,
  currentBuildingId,
  selectZone,
  currentZoneId,
  getBuildingZoneData,
  showMobile,
  activeFilter,
  setActiveFilter
}) => {
  let ZONES = currentBuildingZoneData?.thermalZones
  
  const CATEGORIES = [
    {
      name: 'Elexity Optimized',
      value: 'elexityOptimized'
    },
    {
      name: 'In Override',
      value: 'remoteOverride'
    },
    {
      name: 'In Local Override',
      value: 'localOverride'
    },
    {
      name: 'Out of Range',
      value: 'outOfRange'
    },
    {
      name: 'Very Out of Range',
      value: 'veryOutOfRange'
    },
    {
      name: 'Control Problem',
      value: 'controlProblem'
    },
    {
      name: 'Communications Problem',
      value: 'communicationsProblem'
    },
    {
      name: 'Unknown',
      value: 'unknown'
    },
    {
      name: 'Unmanaged',
      value: 'unmanaged'
    },
    {
      name: 'Problem',
      value: 'problem'
    },
    {
      name: 'Cooling',
      value: 'cooling'
    },
    {
      name: 'Economizing',
      value: 'economizing'
    },
    {
      name: 'Fan',
      value: 'fan'
    },
    {
      name: 'Fault',
      value: 'fault'
    },
    {
      name: 'Heating',
      value: 'heating'
    },
    {
      name: 'Idle',
      value: 'idle'
    },
  ]

  const [showFilter, setShowFilter] = useState(false)
  sessionStorage.removeItem('pieFilter')

  useEffect(() => {
    if (activeFilter.length > 0) {
      setShowFilter(true)
    }
  },[])

  useEffect(() => {
    let interval = setInterval(() => {
      if (currentBuildingId) {
        getBuildingZoneData(currentBuildingId)
        ZONES = currentBuildingZoneData?.thermalZones
      }
      }, 5000)

      return () => clearInterval(interval)
  }, [currentBuildingZoneData])

  useEffect(() => {
    getBuildingZoneData(currentBuildingId)
  }, [currentBuildingId])

  let filteredList
  
  if (activeFilter.length === 0) {
    filteredList = ZONES
  } else {
    filteredList = ZONES?.filter(item => {
      for (let i = 0; i < activeFilter?.length; i++) {
        if (activeFilter[i] === item.activityState?.toLowerCase()) {
          return item
        }
        
        let newItem = item.statusCategory.split(" ").join("")
        newItem = newItem.charAt(0).toLowerCase() + newItem.slice(1)

        if (newItem === activeFilter[i]) {
          return item
        }
      }
    })
  }

  const handleFilterSelect = (filter) => {
    if (activeFilter.includes(filter)) {
      const filterIndex = activeFilter.indexOf(filter)
      const newFilter = [...activeFilter]
      newFilter.splice(filterIndex, 1)
      setActiveFilter(newFilter)
    } else {
      setActiveFilter([...activeFilter, filter])
    }
  }
  
  const ZoneFilters = ({ categories }) => {
    return (<>
      <div className="filter-container">
        <div className={showFilter ? "filter" : "filter hide"} onClick={handleFilter}>
          <div className="filter-name-icon">
            <img src={icon_filter} alt="filter icon" width="14" />
            Filter
          </div>
          
          <Chevron className="chevron" />
        </div>
      
        {showFilter && (
          <div className="filter-items">
            
              {categories.map((category, index) => (
                <div className="filter-item" key={`${category.value}-${index}`}>
                  <label className="container">
                    <input 
                      type="checkbox"
                      checked={activeFilter.includes(category.value)}
                      onChange={() => handleFilterSelect(category.value)}
                      value={category.value}
                    />
                    <span className="checkmark"></span>

                    {category.name}
                  </label>
                </div>
              ))}
            
          </div>
        )}
      </div>
    </>)
  }

  const ZoneList = ({ zones }) => {
    let sortedZoneList = []

    {zones && (
      sortedZoneList = zones.sort((a,b) => {
        if (a.longThermalZoneDisplayName - b.longThermalZoneDisplayName) {
          return 1;
        }
        if (a.longThermalZoneDisplayName < b.longThermalZoneDisplayName) {
          return -1;
        }
        return 0
      })
    )}

    return(
      <section>
        <div className="zone-summary-container">
          <div className={!showMobile ? "card-summary-header desktop" : "card-summary-header"}>
            <h1>Zone Summary <span>{zones?.length} of {ZONES?.length} Zones</span></h1>
            <ZoneFilters categories={CATEGORIES} />
          </div>

          <div className={showMobile ? "zone-cards-container" : "zone-cards-container desktop"}>
            {sortedZoneList?.map(zoneData => (
              <div className="homepage-zone-card" key={`${zoneData.thermalZoneEntityKey}-zonecard`}>
                <HomepageZoneCard
                  zoneRealtimeData={zoneData}
                  selectZone={selectZone}
                  currentZoneId={currentZoneId}
                  currentBuildingId={currentBuildingId}
                  getBuildingZoneData={getBuildingZoneData}
                />
              </div>
            ))}
          </div>
        </div>  
      </section>
    )
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  return (<>
    <section className={!showMobile ? "homepage-section desktop" : "homepage-section"}>
      {!showMobile && currentBuildingZoneData && (<>
        <h1 className="campus-name">{currentBuildingZoneData.longBuildingDisplayName}</h1>

        <CurrentWeather currentCampusData={currentCampusData} />
      </>)}

      <ZoneList zones={filteredList} />
    </section>
  </>)

}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentCampusData: state.currentCampusData,
  }
}

// used by parent class AuthenticatedComponent
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(HomepageZoneCards))