import React, { useState, useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from 'highcharts/modules/exporting'
import MomentTimeZone from 'moment-timezone'
import Moment from 'moment'

import HomepageZoneCard from '../../components/HomepageZoneCard'
import HomepageModalRemoteOverride from '../../components/HomepageModalRemoteOverride'
import HomepageModalScheduleEvent from '../../components/modals/HomepageModalScheduleEvent'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { elexityMobile } from '../../utilities/HVACGraphHelper'
import { elexity } from '../../utilities/AdvancedGraphHelper'
import AuthenticatedComponent from '../../utilities/AuthenticatedComponent'
import configuration from '../../config'
import authentication from '../../utilities/Authentication'

import icon_alert from '../../assets/icon_alert.svg'
import icon_fan from '../../assets/icon_fan.svg'
import icon_heating from '../../assets/icon_heating.svg'
import icon_cooling from '../../assets/icon_cooling.svg'
import icon_idle from '../../assets/icon_idle.svg'
import icon_off from '../../assets/icon_state_off.svg'
import icon_link_arrow from '../../assets/icon_link_arrow.svg'
import CurrentWeather from '../../components/CurrentWeather'

window.moment = Moment
MomentTimeZone()
Moment()

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const HomepageZoneSettings = ({
  thermalZoneSummaryData,
  updateThermalZoneData,
  currentCampusId,
  currentCampusData,
  currentZoneId,
  currentBuildingId,
  currentBuildingZoneData,
  getBuildingZoneData,
  showMobile,
  setCurrentScheduleId,
  openModal
}) => {
  exportingModule(Highcharts)

  const authenticatedComponent = new AuthenticatedComponent

  const [selectedZoneModal, setSelectedZoneModal] = useState(null)
  const [currentLock, setCurrentLock] = useState(false)
  const [currentManaged, setCurrentManaged] = useState()
  const [currentSchedule, setCurrentSchedule] = useState("")
  const [chartTabActive, setChartTabActive] = useState('live')
  const [hasSchedule, setHasSchedule] = useState(false)
  const [hasEvent, setHasEvent] = useState(false)
  const [scheduleName, setScheduleName] = useState(false)
  const [eventName, setEventName] = useState(false)
  const [remoteOverride, setRemoteOverride] = useState(false)
  const [localOverride, setLocalOverride] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [modalMessage, setModalMessage] = useState("")
  const [confirmAction, setConfirmAction] = useState("")
  const [selectionManaged, setSelectionManaged] = useState(false)
  const [zoneSettingsClass, setZoneSettingsClass] = useState('')
  const [buildingName, setBuildingName] = useState(null)
  const [remoteSave, setRemoteSave] = useState(false)

  const graphSubtitle = `${currentBuildingZoneData?.shortBuildingDisplayName}, ${thermalZoneSummaryData?.longThermalZoneDisplayName}`
  const hasOverride = localOverride === true || remoteOverride === true
  const mobileChartRef = useRef(null)
  const historyChartRef = useRef(null)
  const liveChartRef = useRef(null)
  const errorMessageRef = useRef(null)

  useEffect(() => {
    if (remoteSave === true) {
      setProcessing(true)
    } else {
      setProcessing(false)
    }

    if (thermalZoneSummaryData) {
      setCurrentLock(thermalZoneSummaryData.localOverrideLocked)
      setCurrentManaged(thermalZoneSummaryData.unmanaged === false ? true : false)
      setCurrentSchedule(thermalZoneSummaryData.scheduleName 
          ? thermalZoneSummaryData.scheduleName 
          : ""
      )
      setHasSchedule(thermalZoneSummaryData.hasSchedule)
      setHasEvent(thermalZoneSummaryData.hasEvent)
      setScheduleName(thermalZoneSummaryData.scheduleName)
      setEventName(thermalZoneSummaryData.eventName)
      setRemoteOverride(thermalZoneSummaryData.hasOverride)
      setLocalOverride(thermalZoneSummaryData.inLocalOverride)
      setSelectionManaged(thermalZoneSummaryData.unmanaged)
      
      if (thermalZoneSummaryData.statusCategory === "Unmanaged" && zoneSettingsClass === '' && selectedZoneModal !== 'loading') {
        setZoneSettingsClass('unmanaged')
      }
    }
  }, [thermalZoneSummaryData])

  useEffect(() => {
    if (historyChartRef.current && liveChartRef.current) {
      elexity.admin.monitor.graphs.initAuth(authenticatedComponent.generateAuthenticatedHeader())
      elexity.admin.monitor.graphs.init(
        currentZoneId,
        configuration['backend_host']
            + '/admin/monitor/{entityKey}/thermalZone/graphData/definition',
        configuration['backend_host']
            + '/admin/monitor/{entityKey}/thermalZone/graphData/history',
        configuration['backend_host']
            + '/admin/monitor/{entityKey}/thermalZone/graphData/live',
        graphConfiguration,
        "historyPerformanceGraph",
        "livePerformanceGraph",
        graphSubtitle
      )
    }
  }, [historyChartRef.current && liveChartRef.current])

  let mobileGraphUrl = 
  configuration['backend_host']
    + `/admin/monitor/${currentZoneId}/thermalZone/graphData/live`

  const graphConfiguration = ({
    seriesDefinitions : [
      {name: "Current Temperature", color: "#4A5578", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'average'}},
      {name: "Lower Bounds", color: "#0086C9", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'average'}},
      {name: "Target", color: "#16B364", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'average'}},
      {name: "Upper Bounds", color: "#E62E05", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'average'}},
      {name: "Outside Temperature", color: "#EAAA08", type: "spline", opacity: 1, yAxis: 0, dataGrouping: { approximation: 'average'}}      
    ],
    
    plotBandColors : {
        'C' :  "#D1E9FF",
        'H' :  "#FEE4E2",
        'F' :  "#EAECF0"
    },
    
    defaultLiveZoom : 3 
  })

  useEffect(() => {
    if (thermalZoneSummaryData === null) {
      updateThermalZoneData(currentZoneId)
    }
      getBuildingZoneData(currentBuildingId)

      let desktopInterval = setInterval(() => {
        if (currentZoneId !== null) {
          updateThermalZoneData(currentZoneId)
        }
      }, 5000)
  
      return () => clearInterval(desktopInterval)
    // }
  }, [])

  useEffect(() => {
    if (currentBuildingZoneData !== null) {
      setBuildingName(currentBuildingZoneData?.longBuildingDisplayName)
    }
  }, [currentBuildingZoneData])

  function handleRefresh() {
    elexity.admin.monitor.graphs.triggerHistoryGraphReload()
  }

  const clearModal = () => {
    setSelectedZoneModal(null)
  }
  
  const openZoneModal = (selectModal, e, action) => {
    setConfirmAction(action)
    setSelectedZoneModal(selectModal)

    if (selectModal === 'confirm') {
      if (action === 'manage') {
        setModalMessage('Are you sure you want to set this zone to unmanaged?')
        setSelectionManaged(e.target.value)
        if (e.target.value === 'true') {
          handleManaged(e.target.value)
        }
      }

      if (action === 'cancel-override') {
        setModalMessage('Are you sure you want to cancel all overrides for this zone?')
      }
    }
  }

  const renderModal = () => {
    switch(selectedZoneModal) {
      case 'override':
        return <HomepageModalRemoteOverride
                zoneData={thermalZoneSummaryData}
                showMobile={showMobile}
                clearModal={clearModal}
                openModal={openModal}
                setProcessing={setProcessing}
                setRemoteSave={setRemoteSave}
                updateThermalZoneData={updateThermalZoneData}
               />
      case 'event':
        return <HomepageModalScheduleEvent
                zoneData={thermalZoneSummaryData}
                showMobile={showMobile}
                clearModal={clearModal}
                setProcessing={setProcessing}
                updateThermalZoneData={updateThermalZoneData}
                setErrorMessage={setErrorMessage}
               />
      case 'confirm':
        return <ConfirmModal 
                modalMessage={modalMessage}
                modalAction={confirmAction === 'manage' ? 
                  () => handleManaged(selectionManaged) : 
                  () => handleCancelOverride()
                }
                clearModal={clearModal}
               />
      case 'loading':
        return <Loading message='Processing request' />
      default:
        return false
    }
  }

  const handleOpenSchedule = () => {
    setCurrentScheduleId(thermalZoneSummaryData.scheduleId)
    sessionStorage.setItem('scheduleId', thermalZoneSummaryData.scheduleId)
  }

  useEffect(() => {
    if (sessionStorage.scheduleId) {
      window.location.replace(`/schedules/${currentCampusId}`)
    }
  })

  const handleLock = (lockState) => {
    openZoneModal('loading')

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    setCurrentLock(lockState)
  
    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + thermalZoneSummaryData.thermalZoneEntityKey
    + '/thermalZone/lockLocalOverride', 
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `lock=${lockState}`
    })
  
    .then(async response => {
      const res = await response.json()
      
      if (!response.ok) {
        setErrorMessage(() => res.message);

        clearModal();

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateThermalZoneData(currentZoneId)
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }

  const handleSchedule = (newSchedule) => {
    openZoneModal('loading')

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    setCurrentSchedule(newSchedule)

    let newScheduleName = encodeURIComponent(newSchedule)
  
    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + thermalZoneSummaryData.thermalZoneEntityKey
    + '/thermalZone/configuration', 
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `scheduleName=${newScheduleName}`
    })
  
    .then(async response => {
      const res = await response.json()
      if (!response.ok) {
        setErrorMessage(res.message);

        clearModal();

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateThermalZoneData(currentZoneId)
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }

  const handleManaged = (e) => {
    clearModal()

    openZoneModal('loading')
    let newManaged

    if (e === 'true') {
      newManaged = 'normal'
      setZoneSettingsClass('')
    } else {
      newManaged = 'uncontrolled'
      setZoneSettingsClass('unmanaged')
    }

    newManaged = encodeURIComponent(newManaged)

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + thermalZoneSummaryData.thermalZoneEntityKey
    + '/thermalZone/configuration',  
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `operationsFlag=${newManaged}`
    })
  
    .then(async response => {
      const res = await response.json()
      if (!response.ok) {
        clearModal();

        setErrorMessage(res.message);

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateThermalZoneData(currentZoneId)
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }

  const handleCancelOverride = () => {
    openZoneModal('loading')

    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + thermalZoneSummaryData.thermalZoneEntityKey
    + '/thermalZone/override/cancel',
    {
      headers: authenticatedComponent.generateAuthenticatedHeader(),
    })
  
    .then(async response => {
      const res = await response.json()
      if (!response.ok) {
        clearModal();

        setErrorMessage(res.message);

        setTimeout(() => {
          setErrorMessage(null)
        }, 9000)

        throw new Error(res.message)
      } else {
        if (res) {
          updateThermalZoneData(currentZoneId)
        }

        return res
      }
    })

    .then(() => {
      setTimeout(() => {
        clearModal()
      }, 5000)
    })

    .catch(error => {
      clearModal();

      setTimeout(() => {
        if (errorMessage === null && errorMessageRef.current === null) {
          setErrorMessage('Something went wrong. Try again or contact customer support.');
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
        }
      }, 1000)

      console.error(error)
    })
  }

  const ActivityState = () => {
    let activity

    if (thermalZoneSummaryData) {
      if (thermalZoneSummaryData.activityState === 'Off') {
        activity = <div><img src={icon_off} alt='off icon' width="15" height="18" style={{marginRight: 5}}/>Off</div>
      } else if (thermalZoneSummaryData.activityState === 'Heating') {
        activity = <div><img src={icon_heating} alt='heating icon' width="15" height="15" style={{marginRight: 5}}/>Heating</div>
      } else if (thermalZoneSummaryData.activityState === 'Cooling') {
        activity = <div><img src={icon_cooling} alt='snowflake icon' width="15" height="15" style={{marginRight: 5}}/>Cooling</div>
      } else if (thermalZoneSummaryData.activityState === 'Fan') {
        activity = <div><img src={icon_fan} alt='fan icon' width="15" height="15" style={{marginRight: 5}}/>Fan</div>
      }  else if (thermalZoneSummaryData.activityState === 'Idle') {
        activity = <div><img src={icon_idle} alt='fan icon' width="15" height="15" style={{marginRight: 5}}/>Idle</div>
      } else {
        activity = <div><img src={icon_alert} alt='alert icon' width="15" height="15" style={{marginRight: 5}}/>{thermalZoneSummaryData.activityState}</div>
      }
    }

    return (<>
      <div className='data-container'>
        <span>Activity State</span>
        <span>{activity}</span>
      </div>
    </>)
  }
  
  const modal = renderModal();

  return (
    <section className={showMobile ? "homepage-section homepage-zone-settings" : "homepage-section homepage-zone-settings desktop"}>
      {modal}

      {processing && (<Loading message="Processing request"/>)}

      {errorMessage && (
        <div className="zone-message" ref={errorMessageRef}>
          <Message displayMessage={errorMessage} type='error' />
        </div>
      )}

      {!showMobile && (<>
        <h1 className="building-name">{buildingName}</h1>

        <CurrentWeather currentCampusData={currentCampusData}/>
      </>)}

      {thermalZoneSummaryData && (
        <div className={showMobile ? "zone-card-container" : "zone-card-container desktop"}>
          <div className="zone-card">
            <HomepageZoneCard
              zoneRealtimeData={thermalZoneSummaryData}
              currentZoneId={currentZoneId}
              currentBuildingId={currentBuildingId}
              getBuildingZoneData={getBuildingZoneData}
            />
          </div>

          <div className="zone-chart-container desktop">
            <div className="chart-nav">
              <div className="tabs">
                <div
                  className={chartTabActive === 'live' ? "live active" : "live"}
                  onClick={() => setChartTabActive('live')}
                >
                  Live
                </div>
                <div
                  className={chartTabActive === 'history' ? "history active" : "history"}
                  onClick={() => setChartTabActive('history')}
                >
                  History
                </div>
              </div>

              {chartTabActive === 'history' && (
                <div 
                  className="refresh"
                  onClick={() => handleRefresh()}
                >
                  Refresh Graph
                </div>
              )}
            </div>

            <div className="active-chart-container">
              <div
                id="historyPerformanceGraph"
                className={chartTabActive === 'history' ? "performanceGraph" : "hidden performanceGraph"}
                ref={historyChartRef}
              ></div>
              <div
                id="livePerformanceGraph"
                className={chartTabActive === 'live' ? "performanceGraph" : "hidden performanceGraph"}
                ref={liveChartRef}
              ></div>
            </div>
          </div>

          <div className="zone-status-container">
            <div className="zone-status-header">
              <span>Zone Settings</span>
              <hr />
            </div>

            <div className="zone-activity">
                <ActivityState />

                <div className='data-container control-mode'>
                  <span>Schedule</span>
                  {thermalZoneSummaryData && 
                    hasSchedule && scheduleName !== null ? (
                      <div className={!showMobile ? 'schedule-link' : 'schedule-link mobile'} onClick={handleOpenSchedule}>
                        <p>{scheduleName}</p>
                        <img src={icon_link_arrow} alt="link to building details" width={10} />
                      </div>
                    ) : (
                      'None'
                    )}
                </div>

                <div className='data-container control-mode'>
                  <span>Event</span>
                  {thermalZoneSummaryData && 
                    hasEvent ? (
                      <p>{eventName}</p>
                    ) : (
                      'None'
                    )}
                </div>

                <div className='data-container control-mode'>
                  <span>Remote Override</span>
                  {thermalZoneSummaryData && 
                    remoteOverride ? (
                      `Until ${thermalZoneSummaryData.overrideEnds}`
                    ) : (
                      'None'
                    )}
                </div>

                <div className='data-container control-mode'>
                  <span>Local Override</span>
                  {thermalZoneSummaryData && 
                    localOverride ? (
                      `Until ${thermalZoneSummaryData.localOverrideEnds}`
                    ) : (
                      'None'
                    )}
                </div>
            </div>
          </div>
          
          <div className={processing ? 'zone-settings-container processing' : 'zone-settings-container'}>
            <div className="zone-settings-header">
              <span>Modify Zone Settings</span>
              <hr />
            </div>

            <div className="zone-status-details">
              <div className={`zone-dropdown ${zoneSettingsClass}`}>
                <div className="zone-status-label">
                  Schedule
                </div>

                <div className="zone-schedule-select">
                  {thermalZoneSummaryData &&
                    <select 
                      name="schedule"
                      onChange={(e) => handleSchedule(e.target.value)}
                      value={currentSchedule}
                    >
                      {
                        thermalZoneSummaryData.scheduleNames.map((name) => (
                          <option 
                          key={`${name}-schedule-select`}
                          value={name}
                          >
                            {name}
                          </option>
                        ))
                      }
                    </select>
                  }
                </div>
              
              </div>

              <div className={`zone-dropdown ${zoneSettingsClass}`}>
                <div className="zone-status-label">
                  Keypad Lock
                </div>

                <div className="zone-lock-select">
                  <select 
                    name="lock"
                    onChange={(e) => handleLock(e.target.value)}
                    value={currentLock}
                  >
                    <option value={true}>Locked</option>
                    <option value={false}>Unlocked</option>
                  </select>
                </div>
              </div>

              <div className="zone-dropdown">
                <div className="zone-status-label">
                  Managed
                </div>

                <div className="zone-manage-select">
                  <select 
                    name="manage"
                    onChange={(e) => openZoneModal('confirm', e, 'manage')}
                    value={currentManaged}
                  >
                    <option value={true}>Managed</option>
                    <option value={false}>Unmanaged</option>
                  </select>
                </div>
              </div>

              <div className={`zone-modal-buttons ${zoneSettingsClass}`}>
                <div className="zone-status-buttons schedule-button" onClick={() => openZoneModal('event')}>
                  Schedule Event
                </div>

                <div className="zone-status-buttons override-button" onClick={() => openZoneModal('override')}>
                  Remote Override
                </div>

                <div
                  className={hasOverride ? "zone-status-buttons cancel-overrides-button" : "zone-status-buttons cancel-overrides-button disabled"}
                  onClick={(e) => openZoneModal('confirm', e, 'cancel-override')}
                >
                  Cancel Overrides
                </div>
              </div>
            </div>
          </div>
        </div>
      )} 
    </section>
  )
  
}

export default HomepageZoneSettings