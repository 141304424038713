import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import AuthenticatedComponent from '../utilities/AuthenticatedComponent'
import MomentTimeZone from 'moment-timezone'
import Moment from 'moment'

MomentTimeZone();
Moment();

window.moment = Moment;

const authenticatedHeaders = new AuthenticatedComponent
const getHeaders = authenticatedHeaders.generateAuthenticatedHeader
var elexityMobile = elexityMobile || {}

elexityMobile.expui = {    
    entityKeyToken : "",
    entityKeyType : "",
    callBackData: null,
    init : function(entityKeyToken, entityKeyType) {
        elexityMobile.expui.entityKeyToken = entityKeyToken;
        elexityMobile.expui.entityKeyType = entityKeyType;
                
        elexityMobile.expui.pulse();
    },
    
    loadData : function(koiUrl, renderCallback) {
        fetch(koiUrl, { 
            headers: getHeaders()
        } )
        .then(response => {
            if (!response.ok) {
                throw new Error('Request failed')
            }
            return response.json()
        })
        .then(data => {
            renderCallback(data)
            
            elexityMobile.expui.callBackData = data
        })
        .catch(error => console.error(error))
    },
    
    pulseUnhandled : function() {
        // no-op for now.
    },
    
    buildInitialGraphDefinition : function(graphModel, seriesNames, seriesColors, graphType) {
        var mDefaultStart = new Date(Date.now() - (1000 * 60 * 60 * 24));
        var mDefaultEnd = new Date();
    
        var mSeriesDefintions = [];
        seriesNames.forEach(function(mSeriesName, seriesIndex){
            let mSeriesDefinition = {
                name : mSeriesName,
                data : [[mDefaultStart, null]],
                pointStart : mDefaultStart,
                type: graphType
            };
            
            mSeriesDefintions.push(mSeriesDefinition);
        });
        
        var mInitialChartDefinition = {
            chart : { 
                zoomType: 'x',
                height: 300 + 'px',
            },                
            title: { 
                align: 'left',
                floating: true,
                style: {
                    color: '#324E55',
                    'background-color': 'white',
                    fontWeight: 'bold',
                    padding: '3px 10px 0',
                    fontSize: '12px',
                },
                text: graphModel.graphTitle,
                useHTML: true,
                x: 35,
                y: 0,
             },
            subtitle: {
                align: 'left',
                floating: true,
                style: {
                    color: '#324E55',
                    'background-color': 'white',
                    padding: '3px 10px',
                },
                text: graphModel.graphSubtitle,
                useHTML: true,
                x: 35,
                y: 15,
            },
            credits: { 
                enabled: false 
            },               
            xAxis: {
                type: 'datetime',
                labels: {
                    format: isMobile ? ('{value:%H:%M}') : ('{value:%b-%e %H:%M}')
                }
            },
            yAxis: [{
                lineWidth: 1,
                title: {
                    enabled: false
                },
                labels: {
                    format: '{text}' + graphModel.unit
                },
            }],
            legend: {
                layout: 'horizontal',
                itemStyle: {
                    color: '#324E55',
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        x: 10,
                        y: -15,
                    }            
                }
            },
            tooltip: {
                shared: true,
                crosshairs: true,
                useHTML: true,
                headerFormat: '<table style="width: 200px; font-size: smaller;"><tr><th colspan="2">{point.key}</th></tr>',
                pointFormat: '<tr><td style="color: {series.color}">{series.name} </td><td style="text-align: right"><b>{point.y} ' + graphModel.unit + '</b></td></tr>',
                footerFormat: '</table>',
                valueDecimals: graphModel.precision,
                positioner: function(z1, z2, point) {
                    return {
                      x: point.plotX,
                      y: this.chart.plotHeight + this.chart.plotTop - 15
                    }
                }            
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                        radius: 1.5
                    },
                    lineWidth: 1.5,
                }
            },
            
            colors: seriesColors,
            
            time: {
                timezone: graphModel.timeZoneCode
            },
            series: mSeriesDefintions
        };
        
        return mInitialChartDefinition;
    },

    updateGraph : function(graph, graphModel, seriesNames, plotBands, plotLines) {
        // Put new data in place.
        seriesNames.forEach((mSeriesName, seriesIndex) => {
            var mSeriesData = [];
            graphModel.series[mSeriesName].entry.map((mSeriesName, seriesIndex) => {
                var mEntry = [];
                mEntry.push(mSeriesName.dateTime);
                mEntry.push(mSeriesName.value);
                mSeriesData.push(mEntry);
            });
                    
            graph.series[seriesIndex].update({
                data: mSeriesData
            }, false);
        });
        
       // Modify the graph definitions, in case the API is returning other info 
       var mGraphUpdateDefinition = {
            title: { text: graphModel.graphDisplayName },                
            yAxis: [{
                lineWidth: 1,
                title: {
                    enabled: false
                }
            }],
            xAxis: {
                plotBands : plotBands,
                plotLines: plotLines,
            },
            tooltip: {
                shared: true,
                crosshairs: true,
                useHTML: true,
                headerFormat: '<table style="width: 200px; font-size: 12px;"><tr><th colspan="2">{point.key}</th></tr>',
                pointFormat: '<tr><td style="color: {series.color}">{series.name} </td><td style="text-align: right"><b>{point.y} ' + graphModel.unit + '</b></td></tr>',
                footerFormat: '</table>',
                valueDecimals: graphModel.precision
            },
            time: {
               timezone: graphModel.timeZoneCode
            }
        };
                
        // Re-render
        graph.update(mGraphUpdateDefinition, true, true, true);        
    },
}

export { elexityMobile }