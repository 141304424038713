import { useRef, useEffect } from 'react'

const Modal = ({ 
  modalTitle,
  modalDescription,
  modalImage,
  clearModal,
  renderModalContent,
  updateSettings,
  showMobile,
  shouldDisable
}) => {
  const outsideModalRef = useRef(null)
  let headerImage = false

  useEffect(() => {
    window.addEventListener('click', hideModal)

    return () => {
        window.removeEventListener('click', hideModal)
    }
  }, [])

  function hideModal(e) {
    if (e.target === outsideModalRef.current) {
      clearModal()
    }
  }

  const saveUpdates = () => {
    updateSettings()
    clearModal()
  }

  if (modalImage) {
    headerImage = (
      <div className="modal-image">
        <img src={modalImage} alt='modal icon' width="24" height="24" />
      </div>
    )
  }

  return (
    <div className="modal-overlay" ref={outsideModalRef}>
      <div className={showMobile ? "modal" : "modal desktop"}>
        <div className={"modal-header-container" + (modalImage ? ' has-image': '')}>
          {headerImage}

          <div className="modal-header">
            <div className="modal-title">
              {modalTitle}
            </div>

            {modalDescription && 
              <div className="modal-description">{modalDescription}</div>
            }
          </div> 
          
        </div>
        <div className="modal-content">
          {renderModalContent}
        </div>
        <div className="btn-container modal-footer">
            <input type="button" className="frm-btn" onClick={clearModal} value="Cancel" />
            <input type="button" className="frm-btn cta" onClick={saveUpdates} disabled={shouldDisable} value="Confirm" />
        </div>
      </div>
    </div>
  )
  
}

export default Modal