import styled from '@emotion/styled'

const Name = styled.div`
    align-items: flex-start;
    color: #324E55;
    display: flex;
    flex-flow: column;
    max-width: 828px;
    width: 100%;

    .title {
        font-size: 14px;
        font-weight: 500;
    }

    .schedule-input-container {
        background: #F2F4F7;
        border-radius: 8px;
        margin-top: 10px;
        padding: 0;
        width: 100%;
    }

    .schedule-name {
        font-weight: 600;

        input {
            background: transparent;
            border: none;
            box-shadow: none;
            padding: 14px;
            width: calc(100% - 28px);
        }
    }
`

const ScheduleEventName = ({ pageName, handleName, scheduleName }) => (
    <Name className="schedule-name-container">
        <div className="title">{pageName === 'Schedules' ? 'Schedule Name' : 'Event Name'}</div>
        <div className="schedule-input-container">
            <div className="schedule-name">
                <input 
                    placeholder={scheduleName}
                    type="text"
                    maxLength="30"
                    onChange={(e) => handleName(e.target.value)}
                />
            </div>
        </div>
    </Name>
)

export default ScheduleEventName