import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from "highcharts-react-official"
import exportingModule from 'highcharts/modules/exporting'
import moment from"moment"

import WithRouter from '../../utilities/WithRouter'
import icon_link_arrow from '../../assets/icon_link_arrow.svg'
import { elexity } from '../../utilities/ComfortPieGraphHelper'
import { updateCurrentCampusData } from '../../actions'
import CurrentWeather from '../../components/CurrentWeather'

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const HomepageBuildingCards = ({
  currentCampusData,
  updateCampusData,
  currentCampusId,
  selectBuilding,
  activeFilter,
  setActiveFilter
}) => {
  exportingModule(Highcharts)

  // const [filter, setFilter] = useState(null)
  const [searchText, setSearchText] = useState(null)

  const statusChartRef = useRef(null)
  const campusRef = useRef(currentCampusId)

  let campusBuildings = currentCampusData.buildings

  let sortedCampusBuildings = []
  
  sortedCampusBuildings = campusBuildings.sort((a,b) => {
    if (a.buildingDescription.buildingEntityKey - b.buildingDescription.buildingEntityKey) {
      return 1;
    }
    if (a.buildingDescription.buildingEntityKey < b.buildingDescription.buildingEntityKey) {
      return -1;
    }
    return 0
  })
    
  let mBuildingEntityKey

  //Load initial graphs
  useEffect(() => {
    campusBuildings.map((building) => {
      mBuildingEntityKey = building.buildingDescription.buildingEntityKey

      elexity.campus.comfort.statusPieGraph[mBuildingEntityKey] = null
      elexity.campus.comfort.activityPieGraph[mBuildingEntityKey] = null
      // elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey] = null
    })

    elexity.campus.comfort.renderGraphs(currentCampusData)
  }, [])

  //If you change campus, clear graphs and reload
  useEffect(() => {
    setActiveFilter([])
    if (campusRef.current !== currentCampusId) {
      campusBuildings.map((building) => {
        mBuildingEntityKey = building.buildingDescription.buildingEntityKey
  
        elexity.campus.comfort.statusPieGraph[mBuildingEntityKey] = null
        elexity.campus.comfort.activityPieGraph[mBuildingEntityKey] = null
        // elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey] = null
      })
    }

    setTimeout(() => {
      elexity.campus.comfort.renderGraphs(currentCampusData)
    }, 1000)

    campusRef.current = currentCampusId

    //Graph and data update every 5 seconds
    let interval = setInterval(() => {
      if (statusChartRef.current) {
        updateCampusData(currentCampusId)
        elexity.campus.comfort.renderGraphs(currentCampusData)
      }
    }, 5000)

    window.addEventListener('click', addFilter)
  
    return () => {
      window.removeEventListener('click', addFilter)
      clearInterval(interval)
    }
  }, [currentCampusId])

  const handleKeyforId = (buildingKey) => {
    return buildingKey.replaceAll("~", "__")
  }

  const addFilter = (e) => {
    const sliceEvent = e?.target?.className?.baseVal?.includes('highcharts-point')

    if (sliceEvent) {
      let filterName = JSON.parse(sessionStorage.pieFilter).name
      filterName = filterName[0].toLowerCase() + filterName.slice(1)
      filterName = filterName.replace(/ /g, '')
      const filterBuilding = JSON.parse(sessionStorage.pieFilter).building
      setActiveFilter([filterName])
      
      window.location.replace(`/comfort/${currentCampusId}/${filterBuilding}`)
    }
  }

  const searchCards = (e) => {
    setSearchText(e.target.value)
  }

  const handleSelectBuilding = (buildingId) => {
    selectBuilding(buildingId)
  }

  const renderBuildingCards = (sortedCampusBuildings) => {
    return sortedCampusBuildings.filter(buildingData => {
      let keepBuilding = true;
      if (searchText) {
        keepBuilding = buildingData.buildingDescription.shortBuildingDisplayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      }
      return keepBuilding
    }).map((buildingData, index) => {
      let avgTemp = buildingData.thermalZoneAnalysis.avgTemperature
      let minTemp = buildingData.thermalZoneAnalysis.minTemperature
      let maxTemp = buildingData.thermalZoneAnalysis.maxTemperature
      let tempUnit = currentCampusData.campusDescription.temperatureUnit
      
      return (
        <div 
          className='building-row'
          key={`building-card-${index}`}
          >
          {buildingData && buildingData.buildingDescription && (<>
            <div className="building-name">
                <h2>{buildingData.buildingDescription.longBuildingDisplayName}</h2>
                
                <div className="zone-button" onClick={() => handleSelectBuilding(buildingData.buildingDescription.buildingEntityKey)}>View Zones</div>
            </div>
            
            <div className="hompage-campus-detail graph">
              <div className="campus-table-detail">
                <div className="temp-data">
                  <div className="temp-data-item">
                    <div className='temp-label'>Avg Temperature</div>
                    <div className='temp-number'>{avgTemp !== null ? `${avgTemp} °${tempUnit}` : 'No Data'}</div>
                  </div>

                  <div className="temp-data-item">
                    <div className='temp-label'>Max Temperature</div>
                    <div className='temp-number'>{maxTemp !== null ? `${maxTemp} °${tempUnit}` : 'No Data'}</div>
                  </div>

                  <div className="temp-data-item">
                    <div className='temp-label'>Min Temperature</div>
                    <div className='temp-number'>{minTemp !== null ? `${minTemp} °${tempUnit}` : 'No Data'}</div> 
                  </div>
                </div>
              </div>

              <div className={`${buildingData.buildingDescription.buildingEntityKey}-graphs all-graphs`} ref={statusChartRef}>
                <div id={`status-piechart-${handleKeyforId(buildingData.buildingDescription.buildingEntityKey)}`}></div>
                <div id={`activity-piechart-${handleKeyforId(buildingData.buildingDescription.buildingEntityKey)}`}></div>
                {/* <div id={`activity-history-graph-${handleKeyforId(buildingData.buildingDescription.buildingEntityKey)}`}></div> */}
              </div>
            </div>
          </>)}
        </div>
      )
    })
  }

  let buildingCards = <></>

  if (sortedCampusBuildings) {
    buildingCards = renderBuildingCards(sortedCampusBuildings)
  }

  return (
    <section className="homepage-section campus-view desktop">
      <div className="homepage-header">
        <h1 className="campus-name">{currentCampusData.campusDescription.longCampusDisplayName}</h1>

        <CurrentWeather currentCampusData={currentCampusData} />
      </div>

      <div className="building-summary dashboard-cards-summary">
        {/* <div className="header-filter-container">
          <div className="card-summary-filters">
            <input type="search" placeholder="Search Buildings"  onKeyUp={searchCards} />
            <input type="button" className="btn btn-filters" value="Filters" />
          </div>
        </div> */}
        
        <div className="homepage-building-rows">
          {buildingCards}
        </div>
      </div> 
    </section>  
  )
  
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentCampusData: state.currentCampusData,
  }
}

// used by parent class AuthenticatedComponent
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(HomepageBuildingCards))