import React from 'react'
import '../css/zoneCard.css'

import icon_keypad_lock from '../assets/icon_keypad_locked.svg'
import icon_keypad_unlock from '../assets/icon_keypad_unlocked.svg'
import icon_alert from '../assets/icon_alert.svg'
import icon_fan from '../assets/icon_fan.svg'
import icon_heating from '../assets/icon_heating.svg'
import icon_cooling from '../assets/icon_cooling.svg'
import icon_idle from '../assets/icon_idle.svg'
import icon_off from '../assets/icon_state_off.svg'
import icon_connectivity_problem from '../assets/icon_connectivity_problem.svg'

const HomepageZoneCard = ({
  zoneRealtimeData,
  selectZone,
 }) => {

  const formattedZoneRealtime = processRealtimeData(zoneRealtimeData)
  let hasTemperature = formattedZoneRealtime.currentTemperature !== 'N/A'

  return(
    <div 
      className={"summary-card" + formattedZoneRealtime.zoneStatusCssClass}
      onClick={() => selectZone(zoneRealtimeData.thermalZoneEntityKey)}
    >
      <div className="general-view">
        <div className="zone-status-card-header">
          <h3 className="zone-status-card-name">
            {zoneRealtimeData && zoneRealtimeData.longThermalZoneDisplayName}
          </h3>
          <div className="zone-status-card-schedule">
            {formattedZoneRealtime.scheduleOrOverride}
          </div>
        </div>

        <div className={hasTemperature ? "zone-current-status" : "zone-current-status no-temp"}>
          {hasTemperature && (
            <div className="zone-thermostat-container">
              <div className={"zone-status-current-temperature" + formattedZoneRealtime.temperatureClass}>
                {formattedZoneRealtime.currentTemperature}
              </div>

              {formattedZoneRealtime.thermostatIndicator && (
                <div className="zone-thermostat-indicator">
                  <div className={"zone-status-thermostat"}>
                    {formattedZoneRealtime.thermostatIndicator}
                    <span
                      className={"zone-status-thermostat-top" + formattedZoneRealtime.temperatureClass}
                    >
                      {formattedZoneRealtime.temperatureUpperBound}
                    </span>
                    <span
                      className={"zone-status-thermostat-bottom" + formattedZoneRealtime.temperatureClass}
                    >
                      {formattedZoneRealtime.temperatureLowerBound}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="zone-stats">
            <div className="status">
              {formattedZoneRealtime.activityState}
            </div>

            <div className="lock">
            {formattedZoneRealtime.keypadLock}
            </div>
            
            <div className="mode">
              {formattedZoneRealtime.controlState}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  
}

/*** function is meant to be an independent processor for the zone realtime data***/
const processRealtimeData = (zoneRealtimeData) => {

  // default values
  const returnRealtimeData = {
    currentTemperature: 'N/A',
    temperatureLowerBound: null,
    temperatureUpperBound: null,
    activity: <div><img src={icon_alert} alt='alert icon' width="15" /> None</div>,
    thermostatIndicator: null,
    zoneStatusCssClass: ' no-realtime-data',
    thermostatMode: null,
    temperatureClass: '',
    scheduleOrOverride: null,
    keypadLock: <div className="keypad-status zone-stat"><img src={icon_keypad_lock} alt='locked padlock icon' width="12" height="12" /><span>Locked</span></div>,
    controlState: null,
  }

  if (zoneRealtimeData) {
    if (zoneRealtimeData.temperature) {
      returnRealtimeData.currentTemperature = Math.round(zoneRealtimeData.temperature)
      returnRealtimeData.temperatureLowerBound = Math.round(zoneRealtimeData.temperatureLowerBound)
      returnRealtimeData.temperatureUpperBound = Math.round(zoneRealtimeData.temperatureUpperBound)
    
      const boundRange = parseInt(zoneRealtimeData.temperatureUpperBound) - parseInt(zoneRealtimeData.temperatureLowerBound)
      let indicatorPercent = 90 - Math.round((zoneRealtimeData.temperature - parseInt(zoneRealtimeData.temperatureLowerBound)) / boundRange * 90)
      if (indicatorPercent > 90) {
        indicatorPercent = 90
      }
      else if (indicatorPercent < 0) {
        indicatorPercent = 0
      }

      if (!zoneRealtimeData.unmanaged) {
        returnRealtimeData.thermostatIndicator = <div className="zone-status-thermostat-indicator" style={{ top: indicatorPercent + '%' }}></div>
      }
    }

    if (zoneRealtimeData.outOfRange) {
      returnRealtimeData.zoneStatusCssClass = ' warn'
    } else {
      returnRealtimeData.zoneStatusCssClass = ' good'
    }

    if (zoneRealtimeData.veryOutOfRange) {
      returnRealtimeData.zoneStatusCssClass = ' unhealthy'
    }

    if (zoneRealtimeData.offline) {
      returnRealtimeData.zoneStatusCssClass = ' offline'
    }

    if (zoneRealtimeData.unmanaged) {
      returnRealtimeData.zoneStatusCssClass = ' unmanaged'
    }

    returnRealtimeData['keypadLock'] = <div className="keypad-status"><img src={icon_keypad_unlock} alt='unlocked padlock icon' width="12" height="12" /><span>Unlocked</span></div>
    if (zoneRealtimeData['localOverrideLocked']) {
      returnRealtimeData['keypadLock'] = <div className="keypad-status"><img src={icon_keypad_lock} alt='locked padlock icon' width="12" height="12" /><span>Locked</span></div>
    }


    if (zoneRealtimeData.activityState === 'Off') {
      returnRealtimeData.activityState = <div className='activity-state'><img src={icon_off} alt='off icon' width="15" height="18" /></div>
    } else if (zoneRealtimeData.activityState === 'Heating') {
      returnRealtimeData.activityState = <div className='activity-state'><img src={icon_heating} alt='heating icon' width="15" height="15" /></div>
    } else if (zoneRealtimeData.activityState === 'Cooling') {
      returnRealtimeData.activityState = <div className='activity-state'><img src={icon_cooling} alt='snowflake icon' width="15" height="15" /></div>
    } else if (zoneRealtimeData.activityState === 'Fan') {
      returnRealtimeData.activityState = <div className='activity-state'><img src={icon_fan} alt='fan icon' width="15" height="15" /></div>
    } else if (zoneRealtimeData.activityState === 'Idle') {
      returnRealtimeData.activityState = <div className='activity-state'><img src={icon_idle} alt='fan icon' width="15" height="15" /></div>
    } else {
      returnRealtimeData.activityState = <div className='activity-state'><img src={icon_alert} alt='alert icon' width="12" height="12" /><span>{zoneRealtimeData.activityState}</span></div>
    }

    if (zoneRealtimeData.unmanaged) {
      returnRealtimeData.controlState = <div className='control-mode unmanaged'><span>Unmanaged</span></div>
    } else if (zoneRealtimeData.hasControlProblem) {
      returnRealtimeData.controlState = <div className='control-mode problem'><span>Control Problem</span></div>
    } else if (zoneRealtimeData.offline) {
      returnRealtimeData.controlState = <div className='control-mode offline'><img src={icon_connectivity_problem} alt='no connection icon' width="15" height="15" />Connectivity Problem</div>
    } else if (zoneRealtimeData.elexityOptimized) {
      returnRealtimeData.controlState = <div className='control-mode elexity-optimized'><span>Elexity Optimized</span></div>
    }

    if (zoneRealtimeData.inOverride) {
      returnRealtimeData.scheduleOrOverride = <div className='schedule-override'><span>In Remote Override Until: {zoneRealtimeData.overrideEnds}</span></div>
    } else if (zoneRealtimeData.inLocalOverride) {
      returnRealtimeData.scheduleOrOverride = <div className='schedule-override'><span>In Local Override Until: {zoneRealtimeData.localOverrideEnds}</span></div>
    } else if (zoneRealtimeData.inEvent) {
      returnRealtimeData.scheduleOrOverride = <div className='schedule-override'><span>Event Name: <p>{zoneRealtimeData.eventName}</p></span></div>
    } else if (zoneRealtimeData.inSchedule) {
      returnRealtimeData.scheduleOrOverride = <div className='schedule-override'><span>Schedule Name: <p>{zoneRealtimeData.scheduleName}</p></span></div>
    } else if (zoneRealtimeData.unmanaged) {
      returnRealtimeData.scheduleOrOverride = <div className='schedule-override'><span>Unmanaged</span></div>
    }
    
    returnRealtimeData['temperatureClass'] = ' has-temperature'
  }
  else {
    returnRealtimeData['activity'] = false
  }
  return returnRealtimeData
}

export default HomepageZoneCard;
export {processRealtimeData}