import Highcharts from 'highcharts/highstock'

import AuthenticatedComponent from '../utilities/AuthenticatedComponent'
import configuration from '../config'
import MomentTimeZone from 'moment-timezone'
import Moment from 'moment'

MomentTimeZone();
Moment();

window.moment = Moment;

const authenticatedHeaders = new AuthenticatedComponent
const getHeaders = authenticatedHeaders.generateAuthenticatedHeader
var elexity = elexity || {};
elexity.campus = elexity.campus || {};

elexity.campus.comfort = {
    entityKeyToken : "",
    entityKeyType : "",
    pieGraphCallback : {},
	
	statusPieGraph : {},
	activityPieGraph : {},
	activityHistoryGraph : {},
	
	statusPieGraphColors : {
        "Unknown" : "#069BCE",
        "Control Problem" : "#FFE63F",
        "Communications Problem" : "#2FE0E6",
        "Unmanaged" : "#C2D0D1",
        "Local Override" : "#004C93",
        "Remote Override": "#0FFDC2",
        "Very Out Of Range": "#F96138",
        "Out Of Range": "#FA974F",
        "Elexity Optimized": "#00DC92"
    },
    
    activityPieGraphColors : {
        "Problem" : "#F8BB8E",
        "Cooling" : "#9CE4E7",
        "Economizing" : "#9DE5CD",
        "Fan" : "#80CBE4",
        "Fault" : "#FFF4A8",
        "Heating": "#FDB29E",
        "Idle": "#EDF1F2"
    },
    
//	activityHistoryGraphColors : {	
//		'Problem' : "#E478FA",
//		'Cooling' : "#06AED4",
//		'Economizing' : "#66C61C",
//		'Fan' : "#A48AFB",
//		'Fault' : "#528BFF",
//		'Heating': "#E31B54",
//		'Idle' : "#CDD5DF"		
//	},    
    
    init : function(entityKeyToken, entityKeyType, pieGraphCallback) {
        elexity.campus.comfort.entityKeyToken = entityKeyToken;
        elexity.campus.comfort.entityKeyType = entityKeyType;
        
        if (pieGraphCallback == null || pieGraphCallback == undefined) {
			elexity.campus.comfort.pieGraphCallback = function(pieSlice, entityKey) {
				alert("PieGraph slice clicked = " + pieSlice + " for " + entityKey);
			};
		}   
		else {
        	elexity.campus.comfort.pieGraphCallback = pieGraphCallback;      			
		}   
    },
    
    loadData : function() {
        var mUrl = "/ahiapi/" + elexity.campus.comfort.entityKeyToken + "/campus/comfort";
		
        fetch(mUrl, { 
            headers: getHeaders()
        } )
        .then(response => {
            if (!response.ok) {
                throw new Error('Request failed')
            }
            return response.json()
        })
        .then(data => {
            elexity.campus.comfort.render(data)
        })
        .catch(error => console.error(error))
    },

    renderGraphs : function(campusData) {
		campusData.buildings.forEach((buildingModel) => {
			elexity.campus.comfort.buildInitialGraphs(buildingModel);
			elexity.campus.comfort.renderBuildingGraphs(buildingModel);
		});
	},
	
	renderBuildingGraphs : function(buildingModel) {
		var mBuildingEntityKey = buildingModel.buildingDescription.buildingEntityKey;

		elexity.campus.comfort.renderPieGraph(buildingModel.thermalZoneAnalysis.statuses, elexity.campus.comfort.statusPieGraphColors, elexity.campus.comfort.statusPieGraph[mBuildingEntityKey]);
		elexity.campus.comfort.renderPieGraph(buildingModel.thermalZoneAnalysis.activities, elexity.campus.comfort.activityPieGraphColors, elexity.campus.comfort.activityPieGraph[mBuildingEntityKey]);
		
        // if (elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey] !== null) {
        //     elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey].series.forEach((series, seriesIndex) => {
        //         var mSeriesName = series.name;
        //         var mSeriesData = [];

        //         if (buildingModel.thermalZoneAnalysis.activityHistory.series[mSeriesName] == null || buildingModel.thermalZoneAnalysis.activityHistory.series[mSeriesName] == undefined) {
        //             mSeriesData = [];
        //         }
        //         else {
        //             buildingModel.thermalZoneAnalysis.activityHistory.series[mSeriesName].entry.forEach((entry) => {
        //                 var mEntry = [];
        //                 mEntry.push(entry.dateTime);
        //                 mEntry.push(entry.value);

        //                 mSeriesData.push(mEntry);
        //             });
        //         }

        //         elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey].series[seriesIndex].update({
        //             data: mSeriesData
        //         }, true);

        //     });

        //     // Modify the graph definitions, in case the API is returning other info 
        //     var mGraphUpdateDefinition = {
        //         title: { text: buildingModel.thermalZoneAnalysis.activityHistory.graphDisplayName },
        //         yAxis: [{
        //             title: {
        //                 text: buildingModel.thermalZoneAnalysis.activityHistory.unit
        //             },
        //             max: buildingModel.thermalZoneAnalysis.activityHistory.sumMaxYValue,
        //             min: buildingModel.thermalZoneAnalysis.activityHistory.minYValue
        //         }],
        //         //            xAxis : {
        //         //				plotBands : plotBands
        //         //			},		
        //         tooltip: {
        //             shared: true,
        //             crosshairs: true,
        //             useHTML: true,
        //             headerFormat: '<table style="width: 200px; font-size: smaller;"><tr><th colspan="2">{point.key}</th></tr>',
        //             pointFormat: '<tr><td style="color: {series.color}">{series.name} </td><td style="text-align: right"><b>{point.y} ' + buildingModel.unit + '</b></td></tr>',
        //             footerFormat: '</table>',
        //             valueDecimals: buildingModel.thermalZoneAnalysis.activityHistory.precision
        //         },
        //         time: {
        //             timezone: buildingModel.thermalZoneAnalysis.activityHistory.timeZoneCode
        //         }
        //     };

        //     // Re-render
        //     elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey].update(mGraphUpdateDefinition, true, true, true);
        // }  		
	},
	
	renderPieGraph : function(data, colors, pieGraph) {
		var mSlices = elexity.campus.comfort.buildPiechartSlices(data);
		var mColors = elexity.campus.comfort.buildPiechartColors(colors, mSlices);

        pieGraph.series[0].update({
            data: mSlices,
            colors: mColors
        }, true);	
	},
	
	buildPiechartSlices : function(sliceMap) {
        var mData = [];

        for(const key in sliceMap) {
            var mPoint = {
                name : key,
                y: sliceMap[key]
            };
            
            mData.push(mPoint);
        }
        
		return mData;
	},
	
	buildPiechartColors : function(colorMap, slices) {
		var mColors = [];
		slices.forEach(function(pieChartEntry) {
			var mColor = colorMap[pieChartEntry.name];
			if (mColor == null || mColor == undefined) {
				mColor = elexity.campus.comfort.statusPieGraphColors["Unknown"]
			}
			mColors.push(mColor);
		});
		return mColors;
	},	
	
	buildInitialGraphs : function(buildingModel) {
		var mBuildingEntityKey = buildingModel.buildingDescription.buildingEntityKey;
		var mBuildingEntityKeyForId = mBuildingEntityKey.replaceAll("~", "__");
		
		if (elexity.campus.comfort.statusPieGraph[mBuildingEntityKey] == null) {
			var mGraphDiv = "status-piechart-" + mBuildingEntityKeyForId;
			var mPieGraphDefinition = elexity.campus.comfort.buildPieGraphDefinition("Zone Status", mBuildingEntityKey);			
            elexity.campus.comfort.statusPieGraph[mBuildingEntityKey] = Highcharts.chart(mGraphDiv, mPieGraphDefinition); 
		}

		if (elexity.campus.comfort.activityPieGraph[mBuildingEntityKey] == null) {
			var mGraphDiv = "activity-piechart-" + mBuildingEntityKeyForId;
			var mPieGraphDefinition = elexity.campus.comfort.buildPieGraphDefinition("Zone Activity", mBuildingEntityKey);			
            elexity.campus.comfort.activityPieGraph[mBuildingEntityKey] = Highcharts.chart(mGraphDiv, mPieGraphDefinition); 
        };
		
// 		if (elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey] == null) {
// 			var mGraphDiv = "activity-history-graph-" + mBuildingEntityKeyForId;
// 			var mSeriesDefinitions = [
// 				{name: 'Problem', color: elexity.campus.comfort.activityHistoryGraphColors.Problem},
// 				{name: 'Cooling', color: elexity.campus.comfort.activityHistoryGraphColors.Cooling},
// 				{name: 'Economizing', color: elexity.campus.comfort.activityHistoryGraphColors.Economizing},
// 				{name: 'Fan', color: elexity.campus.comfort.activityHistoryGraphColors.Fan},
// 				{name: 'Fault', color: elexity.campus.comfort.activityHistoryGraphColors.Fault},
// 				{name: 'Heating', color: elexity.campus.comfort.activityHistoryGraphColors.Heating},
// 				{name: 'Idle', color: elexity.campus.comfort.activityHistoryGraphColors.Idle}
// 			];         
			
// 			var mGraphConfiguration = {
// 				credits: { enabled: false},                
// 	            chart : { 
// 					type: 'column', zoomType: 'x',
// 				},                
// 	            title: { text: "Zone Activity", floating: false, style : { fontSize: '10px'}, align: 'left' },                
// 	            xAxis: {
// 	                type: 'datetime',
// //			        events: {
// //			          afterSetExtremes: elexity.admin.monitor.building.renderAfterSetBuildingGraphExtremes
// //			        },
// 	                labels: {
// 	                    format: '{value:%b-%e %H:%M}'
// 	                }
// 	            },
// 	            tooltip: {
// 	                shared: true,
// 	                crosshairs: true,
// 	                useHTML: true,
// 	                headerFormat: '<table style="width: 200px; font-size: smaller;"><tr><th colspan="2">{point.key}</th></tr>',
// 	                footerFormat: '</table>'
// 	            },
// 	            plotOptions: {
// 	                series: {
// 	                    marker: {
// 	                        enabled: false,
// 	                        radius: 1.5
// 	                    },
// 	                    stacking: 'normal',
// 			            pointPadding: 0,
// 			            groupPadding: 0,
// 			            borderWidth: 0,
// 			            shadow: false	            
// 	                }
//             	},	            	            
// 	            series: mSeriesDefinitions
// 	        };
	        
// 	        elexity.campus.comfort.activityHistoryGraph[mBuildingEntityKey] = Highcharts.chart(mGraphDiv, mGraphConfiguration); 
// 		}
	},
	
	buildPieGraphDefinition : function(piechartName, buildingId) {
		var mInitialChartDefinition = {
		    chart : { type: 'pie', spacingTop: 2 },
			credits: { enabled: false },                
		    title: { text: piechartName, floating: false, style : { color: '#324E55', fontSize: '14px', fontWeight: '600' }, align: 'center', y: '2'},    
		    tooltip: {
				pointFormat: '{point.y} ({point.percentage:.0f} %)'
		    },
		    plotOptions: {
		        series: {
		            allowPointSelect: true,
		            cursor: 'pointer',
		            dataLabels: { 
						enabled: true, 
						distance: 10,
						format: '<b>{point.name}</b><br/><p style="font-weight: normal">{point.y} ({point.percentage:.0f} %)</p>',
                        style: {
                            color: '#324E55',
                            fontSize: '12px'
                        }
                    }
		        }
		    },
		    series: [{
				name: "Zone Count",
				colorByPoint: true,
				point: {
					events: {
		                click: function (_event) {
							sessionStorage.setItem('pieFilter', JSON.stringify({
                                name: this.name,
                                building: buildingId
                            }))
		                }
		            }
				}
			}]
		};
		
		return mInitialChartDefinition;
	}
}

export { elexity }