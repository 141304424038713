import PropTypes from 'prop-types'
import '../css/meterDetail.css'

import StatsDetail from './StatsDetail'

const MeterCardContainer = ({ 
    gridPower,
    solarPower,
    batteryPower,
    batterySoc,
    hasSolar,
    hasBattery,
    hasEv,
    evPower,
    hvacPower,
    otherPower,
    variant = 'primary'
}) => {
    let currentTime = new Date().toJSON().slice(0, 19)

    return(<>
        <div className="live-meter-data">
            <div className="meter-stats-container" >
                <StatsDetail className="meter-stats">
                    <div className="meter-label">Grid</div>
                    <div>{gridPower === null ? '---' : `${gridPower} kW`}</div>
                </StatsDetail>

                {hasSolar && (
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Solar</div>
                        <div>{solarPower === null ? '---' : `${solarPower} kW`}</div>
                    </StatsDetail>
                )}

                {hasBattery && (<>
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Battery</div>
                        <div>{batteryPower === null ? '---' : `${batteryPower} kW`}</div>
                    </StatsDetail>

                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Battery SOC</div>
                        <div>{batterySoc === null ? '---' : `${batterySoc} %`}</div>
                    </StatsDetail>
                </>)}
                
                <StatsDetail className="meter-stats">
                    <div className="meter-label">HVAC</div>
                    <div>{hvacPower !== null ? `${hvacPower} kW` : '---'}</div>
                </StatsDetail>
                
                {hasEv && (
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">EV</div>
                        <div>{evPower === null ? '---' : `${evPower} kW`}</div>
                    </StatsDetail>
                )}

                {otherPower && (
                    <StatsDetail className="meter-stats">
                        <div className="meter-label">Building/Other</div>
                        <div>{otherPower !== null ? `${otherPower} kW` : '---'}</div>
                    </StatsDetail>
                )}

                {/* <div className="power-health">
                    <div className={meterOnline ? "online" : "offline"}>{meterOnline ? 'Meter Online' : 'Meter Offline'}</div>
                    {hasSolar && (<div className={solarOnline ? "online" : "offline"}>{solarOnline ? 'Solar Online' : 'Solar Offline'}</div>)}
                    {hasBattery && (<div className={batteryOnline ? "online" : "offline"}>{batteryOnline ? 'Battery Online' : 'Battery Offline'}</div>)}
                </div> */}
            </div>
        </div>
    </>)
}

MeterCardContainer.propTypes = {
    meterId: PropTypes.string,
    currentPower: PropTypes.number,
    peakDemandMonth: PropTypes.number,
    kwhMonth: PropTypes.number,
    handleClick: PropTypes.func,
    variant: PropTypes.string,
}

export default MeterCardContainer