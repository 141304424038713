import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Chevron } from '../assets/Chevron'
import { elexity } from '../utilities/AdvancedGraphHelper'
import elexity_logo from '../assets/elexity_logo_full.svg'
import icon_comfort from '../assets/icon_comfort.svg'
import icon_energy from '../assets/icon_energy.svg'
import icon_scheduler from '../assets/icon_scheduler.svg'
import icon_resources from '../assets/icon_resources.svg'
import icon_settings from '../assets/icon_settings.svg'
import icon_notifications from '../assets/icon_notifications.svg'
import icon_logout from '../assets/icon_logout.svg'
import icon_email from '../assets/icon_email.svg'
import icon_menu from '../assets/icon_menu.svg'
import icon_phone from '../assets/icon_phone.svg'
import withRouter from '../utilities/WithRouter'
import authentication from '../utilities/Authentication'
import configuration from '../config'
import AuthenticatedComponent from '../utilities/AuthenticatedComponent'

import {
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentGX,
  setCurrentZone,
  updateCurrentCampusData,
} from '../actions'

const Sidebar = ({
  campuses,
  currentCampusId,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  router,
  currentUserData,
  showMobile,
  updateCurrentCampusData,
  activePage,
  setActivePage,
  currentWindowSize
}) => {
  const [showSidenav, setShowSidenav] = useState(false)
  const [sideNavClass, setSideNavClass] = useState(showMobile ? 'sidenav mobile mobile-hide' : 'sidenav hide')
  const authenticatedComponent = new AuthenticatedComponent
  const getHeader = authenticatedComponent.generateAuthenticatedHeader()
  const overlayRef = useRef(null)
  const campusMenuRef = useRef(null)
  const firstName = currentUserData['first_name'] !== '' ? currentUserData['first_name'] : 'friend'

  function hideSidenav(e) {
    if (e.target === overlayRef.current) {
      resetMenu()
    }
  }

  function resetMenu() {
    if (window.innerWidth < 768 || window.screen.width < 768) {
      setShowSidenav(false)
      setSideNavClass('sidenav mobile mobile-hide')
    } else {
      setShowSidenav(false)
      setSideNavClass('sidenav hide')
    }

    navigateWithValidation(window.location.pathname)
  }

  useEffect(() => {
    resetMenu()

    window.addEventListener('click', hideSidenav)

    return () => {
        window.removeEventListener('click', hideSidenav)
    }
  }, [])

  const navigateWithValidation = (newPath) => {
    if (window.location.pathname !== newPath) {
      router.navigate(newPath)
    }
  }

  useEffect(() => {
    resetMenu()
  }, [currentWindowSize, currentCampusId])

  const onOptionChange = (newCampusId) => {
    setCurrentCampus(newCampusId)

    if (currentCampusId !== newCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + newCampusId
          + '/campus/comfort',
        {
          headers: getHeader
        }
      )

      .then(response => response.json())

      .then(data => {
        updateCurrentCampusData(data)
      })

      .catch(error => console.error(error))
    }

    navigateWithValidation(`/${activePage}/` + newCampusId)
    setCurrentGX(null)

    elexity.admin.monitor.graphs.halt()
    setShowSidenav(false)
  }

  const handleCampusChange = (e) => {
    onOptionChange(e.target.value, null)
  }

  const handlePageChange = (page) => {
    setActivePage(page)

    if (page === 'comfort') {
      setCurrentGX(null)
    } else if (page === 'energy') {
      setCurrentBuilding(null)
      setCurrentZone(null)
    }
  }

  const handleMobileMenuClick = () => {
    setShowSidenav(!showSidenav)
    setSideNavClass('sidenav mobile')
  }

  const handleMenuClick = (e) => {
    if (e.target !== campusMenuRef.current) {
      if (showSidenav) {
        resetMenu()
      } else {
        if (showMobile) {
          setShowSidenav(false)
          setSideNavClass('sidenav hide')
        } else {
          setShowSidenav(true)
          setSideNavClass('sidenav')
        }
      }
    }
  }

  const renderProfileInfo = () => {
    if (currentUserData) {
      return (
        <>
          <div className='user-container'>          
            <div className='sidenav-profile-name'>
              Welcome, {firstName}
            </div>
          </div>
        </>
      )
    }
    return false
  }

  return (
    <>
      {showMobile && (
        <div className='mobile-nav'>
          <div className="mobile-menu-button" onClick={() => handleMobileMenuClick()}>
            <img src={icon_menu} alt='mobile hamburger menu icon' width='24' height='24' />
          </div>

          <div className="mobile-logo">
            <img src={elexity_logo} alt='elexity logo' width='142' height='32' />
          </div>
        </div>
      )}

      <div 
        className={sideNavClass}
        onClick={(e) => handleMenuClick(e)}
      >
        <div className='sidenav-main-container'>
          <div className='sidenav-top'>
            <div className='elexity-logo'>
              <img src={elexity_logo} alt='elexity logo' width='142' height='32' />
            </div>

            {renderProfileInfo()}

            <div className='campus-dropdown'>
              <select 
                className='campus-menu'
                ref={campusMenuRef}
                onChange={handleCampusChange}
                value={(currentCampusId === null) ? '' : currentCampusId}
              >
                <option value="" disabled>Select a Campus</option>
                {campuses && campuses.map((campus) => (
                  <option 
                    key={`side-bar-${campus.campusEntityKey}`}
                    value={campus.campusEntityKey}
                  >
                    {campus.shortCampusDisplayName}
                  </option>
                ))}
              </select> 
            </div>

            <NavLink
              className='sidenav-link comfort'
              to={`/comfort/${currentCampusId}`}
              onClick={() => handlePageChange('comfort')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_comfort}
                  alt='thermometer icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Comfort
              </div>
            </NavLink>

            <NavLink
              className='sidenav-link energy'
              to={`/energy/${currentCampusId}`}
              onClick={() => handlePageChange('energy')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_energy}
                  alt='lighting bolt icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Energy
              </div>
            </NavLink>

            <NavLink
              className='sidenav-link scheduler'
              to={`/schedules/${currentCampusId}`}
              onClick={() => handlePageChange('schedules')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_scheduler}
                  alt='calendar icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Scheduler
              </div>
            </NavLink>

            <NavLink
              className='sidenav-link resources'
              to={'/resources'}
              onClick={() => handlePageChange('resources')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_resources}
                  alt='info icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Resources
              </div>
            </NavLink>

            {activePage === 'resources' && (
              <div className='subnav-resources'>
                <span>Support</span>

                <div className='support-details'>
                  <p>Hours: 9am - 5pm (PST)</p>
                  <p>Callback within: 
                    <br/>
                    1 hour (Weekdays)
                    <br/>
                    4 hours (Weekends)
                  </p>
                </div>

                <div className='contact'>
                  <a href='mailto:support@elexity.io' className='email-us'>
                    <img
                      src={icon_email}
                      alt='email icon'
                      width='14'
                      height='14'
                    />
                    support@elexity.io
                  </a>

                  <a href='tel:5102259329' className='call-us'>
                    <img
                      src={icon_phone}
                      alt='phone icon'
                      width='14'
                      height='14'
                    />
                    510-225-9329
                  </a>
                </div>
              </div>
            )}
          </div>
          
          <div className='sidenav-bottom'>
            <NavLink
              className='sidenav-link settings'
              to={'/settings'}
              onClick={() => handlePageChange('settings')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_settings}
                  alt='gear icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Settings
              </div>
            </NavLink>

            <NavLink
              className='sidenav-link notifications'
              to={'/notifications'}
              onClick={() => handlePageChange('notifications')}
            >
              <div className='menu-icon'>
                <img
                  src={icon_notifications}
                  alt='bell icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Notifications
              </div>
            </NavLink>

            <a
              className='sidenav-link logout'
              onClick={authentication.logout}
              href={authentication.logoutLink}
            >
              <div className='menu-icon'>
                <img
                  src={icon_logout}
                  alt='logout icon'
                  width='24'
                  height='24'
                />
              </div>
              
              <div className="menu-name">
                Log Out
              </div>
            </a>
          </div>
        </div>
      </div>

      <div 
        className={showSidenav ? 'sidenav-overlay show' : 'sidenav-overlay'} 
        ref={overlayRef}
      ></div>
    </>
  )
  
}
const mapStateToProps = (state) => {
  return {
    campuses: state.campuses,
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))