import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { elexity } from '../utilities/AdvancedGraphHelper'
import icon_chevron from '../assets/icon_chevron.svg'

import {
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  updateCurrentCampusData
} from '../actions'

import WithRouter from '../utilities/WithRouter'

const PageHeader = ({ 
  currentCampusData,
  currentCampusId,
  currentBuildingId,
  currentZoneId,
  currentGXId,
  hasSingleGX,
  activePage,
  onZoneChange,
  showMobile,
  onBuildingChange,
  onGXChange,
  router
}) => {
  const [currentBuildingName, setCurrentBuildingName] = useState()
  const zoneParam = window.location.pathname.split('/')[4]
  const pageUrl = window.location.pathname.split('/')[1]
  const showHeader = (pageUrl === 'comfort' || pageUrl === 'energy')
  const showBuildingNav = currentCampusData && currentBuildingId
  const showGXNav = currentCampusData && !currentBuildingId && currentGXId

  const navigateWithValidation = (newPath) => {
    if (router.location.pathname !== newPath) {
        router.navigate(newPath)
    }
  }

  useEffect(() => {
    //set building when campus data loads
    if (currentCampusData && currentCampusData.buildings) {
      if (currentZoneId) {
        currentCampusData && currentCampusData.buildings.filter((building) => {
          let tempName
          if (building.buildingDescription.buildingEntityKey === currentBuildingId) {
            tempName = building.buildingDescription.shortBuildingDisplayName
            tempName = tempName.split(' - ').pop()
            setCurrentBuildingName(tempName)
          }
        })
      }
    }
  }, [currentCampusData])

  useEffect(() => {
    //set building name when zone changes
    if (currentCampusData && currentCampusData.buildings) {
      if (currentZoneId) {
        currentCampusData && currentCampusData.buildings.filter((building) => {
          let tempName
          if (building.buildingDescription.buildingEntityKey === currentBuildingId) {
            tempName = building.buildingDescription.shortBuildingDisplayName
            tempName = tempName.split(' - ').pop()
            setCurrentBuildingName(tempName)
          }
        })
      }
    }
  }, [currentZoneId])

  const handleSubNavCampus = () => {
    if (currentBuildingId) {
      onBuildingChange(null)
    } else if (currentGXId) {
      onGXChange(null)
    }

    elexity.admin.monitor.graphs.halt()

    navigateWithValidation(`${activePage}/` + currentCampusId)
  }

  const handleSubNavBuilding = () => {
    elexity.admin.monitor.graphs.halt()
    onZoneChange(null)

    navigateWithValidation(`${activePage}/` + currentCampusId + '/' + currentBuildingId)
  }

  useEffect(() => {
    if (currentCampusData && currentCampusData.buildings && showMobile) {
      currentCampusData.buildings.filter((building) => {
        if (building.buildingDescription.buildingEntityKey === currentBuildingId) {
          setCurrentBuildingName(building.buildingDescription.longBuildingDisplayName)
          
          if (zoneParam === undefined || zoneParam === null) {
            onZoneChange(null)
          }          
        }
      })
    }
  }, [currentCampusData, onBuildingChange])

  const handleRemoveZone = () => {
    onZoneChange(null)

    navigateWithValidation('/home/' + currentCampusId + '/' + currentBuildingId)
  }

  return (<>
    {showHeader && (
      <header className={showMobile ? 'page-header mobile' : 'page-header'}>
        <div className='main-navigation'>
          {(showBuildingNav || showGXNav) && 
            !hasSingleGX &&
            currentCampusData?.campusDescription &&
          (
            <nav className='desktop-subnav'>
              <div 
                className="campus-name"
                onClick={() => handleSubNavCampus()}
              >
                <img src={icon_chevron} alt="indicator arrow to go back to campus" width={15} />
                {currentCampusData.campusDescription.longCampusDisplayName}
              </div>

              {currentZoneId && currentBuildingId && (
                <div 
                  className="building-name"
                  onClick={() => handleSubNavBuilding()}
                >
                  <img src={icon_chevron} alt="indicator arrow to go back to building" width={15} />
                  {currentBuildingName}
                </div>
              )}
            </nav>
          )}
        </div>
      </header>
    )}
  </>)
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(PageHeader))