import { useState } from 'react'
import styled from '@emotion/styled'
import icon_plus from '../../assets/icon_plus.svg'
import icon_minus from '../../assets/icon_minus.svg'

const TemperatureBox = styled.div`
.temperature-box-header {
  color: #324E55;
  font-weight: 500;
  padding:0 0 6px 0;
}

.temperature-box {
  align-items: center;
  color: #324E55;
  display: flex;
  height: 64px;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  padding: 4px 8px;
  width: 60px;

  .selected-temperature {
    font-size: 24px;
    font-weight: 700;
  }
  
  .increment-temp, .decrement-temp {
    padding: 4px 0;
  }

  .increment-temp input {
    background:#FFFFFF url(${icon_plus}) no-repeat center center;
    background-size: 12px 12px;
  }
  .decrement-temp input  {
    background: #FFFFFF url(${icon_minus}) no-repeat center center;
    background-size: 12px 12px;
  }
  
  .increment-temp input,
  .decrement-temp input {
    border: 2px solid #EAECF0;
    border-radius: 4px;
    height: 20px;
    text-align: center;
    width: 20px;
  }
}
`

const FormTemperatureBox = ({ defaultValue, updateField, label }) => {
  const [value, setValue] = useState(defaultValue)

  const updateTemperature = (e) => {
    if (e.target.getAttribute('action') === '+') {
      setValue(value + 1)
      updateField(value + 1)
    }
    else {
      setValue(value - 1)
      updateField(value - 1)
    }
  }

  return (
    <TemperatureBox>
      <div className="temperature-box-header">{label}</div>
      <div className="temperature-box">
        <div className="selected-temperature">
          {value}
        </div>

        <div className="temperature-adjustments">
          <div className="increment-temp">
            <input
              type="button"
              action="+"
              onClick={updateTemperature} />
          </div>
          <div className="decrement-temp">
            <input
              type="button"
              action="-"
              onClick={updateTemperature} />
          </div>
        </div>
      </div>
    </TemperatureBox>
  )
  
}

export default FormTemperatureBox