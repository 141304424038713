import React, { useState, useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from 'highcharts/modules/exporting'

import '../../css/meterDetail.css'
import { elexity } from '../../utilities/AdvancedGraphHelper'
import AuthenticatedComponent from '../../utilities/AuthenticatedComponent'
import configuration from '../../config'
import { setCurrentGX } from '../../actions'
import { connect } from 'react-redux'
import WithRouter from '../../utilities/WithRouter'

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const EnergyUtilityMeterDetail = ({ 
    currentGXId,
    currentGXData,
    meterSelected,
    hasSingleGX,
}) => {
    exportingModule(Highcharts)

    const [gxName, setGXName] = useState(null)
    const [chartTabActive, setChartTabActive] = useState('live')
    const [graphSubtitle, setGraphSubtitle] = useState('')
    const [billData, setBillData] = useState([])
    const [hasBillData, setHasBillData] = useState(false)
    
    const authenticatedComponent = new AuthenticatedComponent

    function handleRefresh() {
        elexity.admin.monitor.graphs.triggerHistoryGraphReload()
    }

    useEffect(() => {
        getBills()
    }, [currentGXId])

    useEffect(() => {
        if (currentGXData?.gridInterconnects) {
            currentGXData.gridInterconnects.filter((gx) => {
                if (gx.gridInterconnectDescription.gridInterconnectEntityKey === meterSelected) {
                    setGXName(gx.gridInterconnectDescription.longGridInterconnectDisplayName)
                }

                if (gx.gridInterconnectDescription.gridInterconnectEntityKey === meterSelected) {
                    if (historyChartRef.current && liveChartRef.current && graphSubtitle === '') {
                        setGraphSubtitle(gx.gridInterconnectDescription.longGridInterconnectDisplayName)

                        elexity.admin.monitor.graphs.initAuth(authenticatedComponent.generateAuthenticatedHeader())
                        elexity.admin.monitor.graphs.init(
                            meterSelected,
                            configuration['backend_host']
                                + '/admin/monitor/{entityKey}/gridInterconnect/graphData/definition',
                            configuration['backend_host']
                                + '/admin/monitor/{entityKey}/gridInterconnect/graphData/history',
                            configuration['backend_host']
                                + '/admin/monitor/{entityKey}/gridInterconnect/graphData/live',
                            graphConfiguration,
                            "historyPerformanceGraph",
                            "livePerformanceGraph",
                            gx.gridInterconnectDescription.longGridInterconnectDisplayName
                        )
                    }
                }
            })    
        }
    }, [currentGXData])

    const historyChartRef = useRef(null)
    const liveChartRef = useRef(null)

    //Load initial graphs
    const graphConfiguration = ({
        seriesDefinitions : [
            {name: "Grid Power", color: "#FA974F", type: "spline", opacity: 1, dataGrouping: { approximation: 'average'}},
            {name: "Solar Power", color: "#F5D500", type: "spline", opacity: 1, dataGrouping: { approximation: 'average'}},
            {name: "HVAC Power", color: "#069BCE", type: "spline", opacity: 1, dataGrouping: { approximation: 'average'}},
            {name: "EV Charger Power", color: "#0FFDC2", type: "spline", opacity: 1, dataGrouping: { approximation: 'average'}},
            {name: "Battery Power", color: "#02BE7F", type: "spline", opacity: 1, dataGrouping: { approximation: 'average'}},
            {name: "Managed Load", color: "#ADD8E6", type: "column", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'average'}},
            {name: "Limit", color: "#324E55", type: "spline", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'average'}},
            {name: "Unmanaged Load", color: "#CCCCCC", type: "column", stacking: 'normal', opacity: .75, dataGrouping: { approximation: 'average'}},
            {name: "Total Load", color: "#2FE0E6", type: "spline", opacity: 1, dataGrouping: { approximation: 'average'}}       
        ],

        defaultLiveZoom : 3
    })

    const handleKeyforId = (gxKey) => {
        return gxKey.replaceAll("~", "__")
    }

    function formatMonth(date) {
        const newDate = new Date(date)
        return newDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })
    }

    function formatStartEndDate(date) {
        return date.split("T")[0]
    }

    function generatePDFBill(pdfUri, fileName) {
        fetch(
            configuration['backend_host'] + '/ahiapi/' 
            + currentGXId + '/gridInterconnect/bills/pdf?uri=' + pdfUri + '&filename=' + `${fileName}.pdf`, 
            {
            headers: authenticatedComponent.generateAuthenticatedHeader()
            }
        )

        .then(response => {
            if (!response.ok) {
                throw new Error('Not Accessible')
            }
            return response
        })

        .then(data => {
            let alink = document.createElement("a");
            alink.href = data.url;
            // alink.download = fileName;
            alink.click();
        })

        .catch(error => {
            console.error(error)
        })
    }

    function getBills() {
        fetch(
            configuration['backend_host'] + '/ahiapi/' 
            + currentGXId + '/gridInterconnect/bills', 
            {
              headers: authenticatedComponent.generateAuthenticatedHeader()
            }
        )
        
        .then(response => {
            if (!response.ok) {
                throw new Error('Not Accessible')
            }
            return response.json()
        })

        .then(data => {
            setBillData(data)
            
            if (data.utilityApiDataAvailable === true) {
                setHasBillData(true)
            }
        })

        .catch(error => {
            setBillData([])
            console.error(error)
        })
    }

    return (<>
        {!hasSingleGX && (<h1 className="campus-name">{gxName ? gxName : 'Utility Meter'}</h1>)}
        
        <div className="gx-chart-container">
            <div className="chart-nav">
                <div className="tabs">
                    <div
                        className={chartTabActive === 'live' ? "live active" : "live"}
                        onClick={() => setChartTabActive('live')}
                    >
                        Live
                    </div>
                    <div
                        className={chartTabActive === 'history' ? "history active" : "history"}
                        onClick={() => setChartTabActive('history')}
                    >
                        History
                    </div>
                </div>

                {chartTabActive === 'history' && (
                    <div 
                        className="refresh"
                        onClick={() => handleRefresh()}
                    >
                        Refresh Graph
                    </div>
                )}
            </div>

            <div className="active-chart-container">
                <div
                    id="historyPerformanceGraph"
                    className={chartTabActive === 'history' ? "performanceGraph" : "hidden performanceGraph"}
                    ref={historyChartRef}
                ></div>
                <div
                    id="livePerformanceGraph"
                    className={chartTabActive === 'live' ? "performanceGraph" : "hidden performanceGraph"}
                    ref={liveChartRef}
                ></div>
            </div>
        </div>
        
        <div className="bill-analysis">
            <h4>Bill Analysis</h4>
            <hr />

            {hasBillData ? (
                <div className="bill-table">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>kWh</th>
                                <th>Total $</th>
                                <th></th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            {billData.length !== 0 && (
                                billData.energyBills.map((item, index) => (
                                    <tr key={`bill-${index}`}>
                                        <td>{formatMonth(item.startDate)}</td>
                                        <td>{formatStartEndDate(item.startDate)}</td>
                                        <td>{formatStartEndDate(item.endDate)}</td>
                                        <td>{`${item.energykWh} kWh`}</td>
                                        <td>{`$${item.totalCost}`}</td>
                                        <td className='bill-download'>
                                            <button 
                                                id='pdf-bill-button' 
                                                onClick={
                                                    () => generatePDFBill(item.pdfDownloadUrl, `${formatMonth(item.startDate)}-${currentGXId}_bill`)
                                                    }
                                            >
                                                Get My Bill
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            ):(
                <div className="no-data">To enable tracking of your utility bills please send a request to <a href='mailto:support@elexity.io'>support@elexity.io</a>.</div>
            )}
        </div>
    </>)
}

const mapStateToProps = (state) => {
    return {
      campuses: state.campuses,
      currentCampusId: state.currentCampusId,
      currentGXId: state.currentGXId,
      currentCampusData: state.currentCampusData,
      currentGXData: state.currentGXData
    }
  }
  
  // used by parent class AuthenticatedComponent
  const mapDispatchToProps = (dispatch) => {
    return {
      setCurrentGX: (gxId) => {
        dispatch(setCurrentGX(gxId))
      },
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(EnergyUtilityMeterDetail))